import React, { forwardRef } from "react";

import { Box } from "../generic";
import {
  statusMessageTitleProps,
  statusMessageContentProps,
  AssistanceMessage,
} from "./StatusView.utils";
import { TSubmissionData } from "../lib";
import ViewContainer from "../ViewContainer";

const OfferRescindedMessage = forwardRef(
  (
    {
      submissionData,
    }: {
      submissionData: TSubmissionData;
    },
    ref: React.Ref<HTMLHeadingElement>
  ) => {
    const { title: manuscriptTitle } = submissionData;
    return (
      <ViewContainer
        viewName="status"
        htmlTitle={`Suggestions no longer available for ${manuscriptTitle}`}
        topTitle="Status update for"
        submissionData={submissionData}
      >
        <Box color="info" iconName="info-solid-circle" variant="background">
          <h1 {...statusMessageTitleProps} ref={ref}>
            Suggestions no longer available
          </h1>
          <p {...statusMessageContentProps}>
            The transfer suggestions for your manuscript are no longer available
            because an editor has taken action on the journal site. Please go to
            the journal site for more information.
          </p>
          <AssistanceMessage />
        </Box>
      </ViewContainer>
    );
  }
);

export default OfferRescindedMessage;
