import React, { forwardRef } from "react";

import * as lib from "../lib";

import ViewContainer from "../ViewContainer";
import LoadingBar from "../generic/LoadingBar/LoadingBar";
import { BetaBadge, Box } from "../generic";
import { _evaluateH1Size } from "./EvalutateView.css";

const EvaluateInProgress = forwardRef(
  (
    { submissionData }: { submissionData: lib.TSubmissionData },
    ref: React.Ref<HTMLHeadingElement>
  ) => {
    return (
      <ViewContainer
        viewName="evaluate:in-progress"
        htmlTitle={`Analysing your manuscript for ${submissionData.title}`}
        topTitle="Improve your manuscript for"
        submissionData={submissionData}
      >
        <Box>
          <BetaBadge id="is-beta" />
          <h1
            aria-describedby="is-beta"
            css={_evaluateH1Size}
            data-testid="evaluate-title"
            ref={ref}
            tabIndex={-1}
          >
            <strong>We are analysing your manuscript</strong>
          </h1>
          <p>
            This can take up to 5 to 10 minutes, please don&apos;t reload the page.
          </p>
          <LoadingBar />
        </Box>
      </ViewContainer>
    );
  }
);

export default EvaluateInProgress;
