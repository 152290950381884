import React from "react";

import {
  isOpenAccessTypeFull,
  isOpenAccessTypeHybrid,
  isOpenAccessTypeNone,
  isOpenAccessTypeSubsidised,
  TAPCWaiver,
  TOpenAccessType,
  TMergeElementProps,
} from "../lib";

import * as css from "./OpenAccessInfo.css";

import { Badge } from "../generic";

type TOpenAccessInfo = TMergeElementProps<
  "ul",
  {
    apcWaiver?: TAPCWaiver;
    openAccessType: TOpenAccessType;
    ranking: number;
  }
>;

const OpenAccessInfo = ({
  apcWaiver,
  forwardCSS,
  openAccessType,
  ranking,
}: TOpenAccessInfo) => {
  const publiclyAvailableText = `Your article will be made publicly available upon publication.`;

  const openAccessBadge = (
    <Badge
      testId={`open-access-badge-${ranking}`}
      forwardCSS={css._openAccessOpenAccessBadgeCSS}
    >
      Open Access
    </Badge>
  );

  const subscriptionBadge = (
    <Badge
      testId={`subscription-badge-${ranking}`}
      forwardCSS={css._openAccessSubscriptionBadgeCSS}
    >
      Subscription
    </Badge>
  );

  const apcWaiverInfo = (waiver: TAPCWaiver) => (
    <>
      <span
        css={css._openAccessCostSummaryCSS}
        data-testid="open-access-discount"
      >
        {waiver.discount}% discount
      </span>
      {/* Date comes preformatted in this case, long story */}
      <span css={css._openAccessCostDetailsCSS}>
        Applies for submissions made before {waiver.expires}.
      </span>{" "}
      <span css={css._openAccessCostDetailsCSS}>{publiclyAvailableText}</span>
    </>
  );

  const fullChargeInfo = !apcWaiver && (
    <>
      <span
        css={css._openAccessCostSummaryCSS}
        data-testid="open-access-charges"
      >
        Charges apply
      </span>
      <span css={css._openAccessCostDetailsCSS}>{publiclyAvailableText}</span>
    </>
  );

  const openAccessInfo = (
    <>
      {openAccessBadge}
      {apcWaiver ? apcWaiverInfo(apcWaiver) : fullChargeInfo}
    </>
  );

  const sponsoredInfo = (
    <>
      {openAccessBadge}
      <span
        css={css._openAccessCostSummaryCSS}
        data-testid="open-access-no-charges"
      >
        No publishing charge
      </span>
      <span css={css._openAccessCostDetailsCSS}>
        The publishing charge is sponsored by a third party.{" "}
        {publiclyAvailableText}
      </span>
    </>
  );

  const subscriptionInfo = (
    <>
      {subscriptionBadge}
      <span
        css={css._openAccessCostSummaryCSS}
        data-testid="open-access-no-charges"
      >
        No publishing charge
      </span>
      <span css={css._openAccessCostDetailsCSS}>
        Your article can be shared according to this journal&apos;s article
        sharing policy.
      </span>
    </>
  );

  const publicationInfo: React.ReactNode[] = [];

  if (isOpenAccessTypeFull(openAccessType)) {
    publicationInfo.push(openAccessInfo);
  } else if (isOpenAccessTypeHybrid(openAccessType)) {
    publicationInfo.push(openAccessInfo);
    publicationInfo.push(subscriptionInfo);
  } else if (isOpenAccessTypeSubsidised(openAccessType)) {
    publicationInfo.push(sponsoredInfo);
  } else if (isOpenAccessTypeNone(openAccessType)) {
    publicationInfo.push(subscriptionInfo);
  }

  return (
    <ul css={[forwardCSS, css._openAccessInfoCSS]}>
      {publicationInfo.map((info, index) => {
        return (
          <li css={css._openAccessItemCSS} key={index}>
            {info}
          </li>
        );
      })}
    </ul>
  );
};

export default OpenAccessInfo;
