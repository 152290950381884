type TMinorCASRanking = {
  name: string;
  tier: number;
};

type TMajorCASRanking = TMinorCASRanking & {
  top: boolean;
};

export interface TCASInformation {
  minorRanking: TMinorCASRanking[];
  majorRanking: TMajorCASRanking;
}
