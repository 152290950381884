/*
  The evils of enums in Typescript: https://dev.to/ivanzm123/dont-use-enums-in-typescript-they-are-very-dangerous-57bh
  Best solution: https://www.typescriptlang.org/docs/handbook/enums.html#objects-vs-enums
*/

export const OfferType = {
  REJECT_WITH_AI_SUGGESTIONS: "RejectWithAISuggestions",
  TRANSFER_BY_SCIENTIFIC_MANAGING_EDITOR: "TransferByScientificManagingEditor",
  TRANSFER_WITH_EDITOR_SUGGESTIONS: "TransferWithEditorSuggestions",
  UNKNOWN: "Unknown", // For when things really blow up
} as const;
export type TOfferType = (typeof OfferType)[keyof typeof OfferType];

export type TRejectWithAISuggestions =
  typeof OfferType.REJECT_WITH_AI_SUGGESTIONS;
export type TTransferByScientificManagingEditor =
  typeof OfferType.TRANSFER_BY_SCIENTIFIC_MANAGING_EDITOR;
export type TTransferWithEditorSuggestions =
  typeof OfferType.TRANSFER_WITH_EDITOR_SUGGESTIONS;
export type TOfferTypeUnknown = typeof OfferType.UNKNOWN;

export function isRejectWithAISuggestions(
  t: string
): t is TRejectWithAISuggestions {
  return t === OfferType.REJECT_WITH_AI_SUGGESTIONS;
}

export function isTransferByScientificManagingEditor(
  t: string
): t is TTransferByScientificManagingEditor {
  return t === OfferType.TRANSFER_BY_SCIENTIFIC_MANAGING_EDITOR;
}

export function isTransferWithEditorSuggestions(
  t: string
): t is TTransferWithEditorSuggestions {
  return t === OfferType.TRANSFER_WITH_EDITOR_SUGGESTIONS;
}

export function isSourceUnknown(t: string): t is TOfferTypeUnknown {
  return t === OfferType.UNKNOWN;
}
