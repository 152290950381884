import { css } from "@emotion/react";
import { _externalFooterButtonCSS, _minMediaCSS } from "../lib";
import { _utilBarContainerCSS } from "../generic/UtilBar/UtilBar.css";

export const _footerContainerCSS = css`
  padding: var(--spacing-xl) var(--page-gap);
  border-bottom: 1px solid var(--color-border);
  border-top: 2px solid var(--color-brand);
  .ot-sdk-show-settings {
    ${_externalFooterButtonCSS}
  }
`;

export const _footerCSS = css`
  display: flex;
  flex-direction: column;
  gap: var(--row-gap) var(--column-gap);
  font-size: var(--font-size-s);
  color: var(--color-nav-link);
  ${_minMediaCSS("m")} {
    flex-direction: row;
  }
  p + p {
    margin-top: var(--font-size-xs);
  }
`;

export const _footerContentCSS = css`
  flex: 1;
`;

export const _footerLinksCSS = css`
  ${_utilBarContainerCSS}
  justify-content: flex-start;
  align-items: baseline;
  flex-direction: column;
  gap: var(--row-gap) var(--column-gap);
  padding: 0;
  list-style: none;
  ${_minMediaCSS("s")} {
    flex-direction: row;
  }
`;
