import React, { forwardRef } from "react";

import { Box } from "../generic";
import {
  statusMessageTitleProps,
  statusMessageContentProps,
  AssistanceMessage,
} from "./StatusView.utils";
import MoreTransferOptions from "../MoreTransferOptions";
import ViewContainer from "../ViewContainer";
import { TSubmissionData } from "../lib";

const OfferDeclinedMessage = forwardRef(
  (
    {
      declinedOn,
      submissionData,
    }: {
      // Declined on date may not be available if transfer was declined in Editorial Manager
      declinedOn?: string;
      submissionData: TSubmissionData;
    },
    ref: React.Ref<HTMLHeadingElement>
  ) => {
    const { title: manuscriptTitle } = submissionData;

    return (
      <ViewContainer
        viewName="status"
        htmlTitle={`Suggestions declined for ${manuscriptTitle}`}
        topTitle="Status update for"
        submissionData={submissionData}
      >
        <Box color="info" iconName="info-solid-circle" variant="background">
          <h1 {...statusMessageTitleProps} ref={ref}>
            All transfer suggestions declined
          </h1>
          {declinedOn && (
            <p {...statusMessageContentProps}>
              You declined all transfer suggestions for your manuscript{" "}
              {declinedOn && (
                <>
                  on <strong>{declinedOn}</strong>
                </>
              )}
              . You will no longer receive email reminders for this transfer
              offer.
            </p>
          )}
          <AssistanceMessage />
        </Box>
        <MoreTransferOptions />
      </ViewContainer>
    );
  }
);

export default OfferDeclinedMessage;
