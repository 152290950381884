import React, { useReducer } from "react";

import { useFocusRef } from "../lib";
import { Button, Box } from "../generic";
import * as css from "./ManuscriptInfo.css";

export const ManuscriptInfo = ({
  isMainTitle,
  manuscriptTitle,
  feederJournalTitle,
  pubDNumber,
}: {
  isMainTitle: boolean;
  manuscriptTitle: string;
  feederJournalTitle: string;
  pubDNumber: string;
}) => {
  const [isToggledOpen, dispatchToggle] = useReducer((state) => {
    return !state;
  }, false);

  const focusRef = useFocusRef<HTMLParagraphElement>({
    documentCookie: document.cookie,
    dependencies: [isToggledOpen],
    shouldFocus: isToggledOpen,
  });

  const toggledClass = isToggledOpen ? "open" : "closed";

  const Title = isMainTitle ? "h2" : "p";

  return (
    <Box
      element={isMainTitle ? "section" : "div"}
      forwardCSS={css._manuscriptInfoContainerCSS}
      variant="background"
    >
      <Title
        className={toggledClass}
        css={css._manuscriptTitleCSS}
        data-testid="manuscript-title"
      >
        {manuscriptTitle}
      </Title>
      <p
        className={toggledClass}
        css={css._manuscriptDetailsItemCSS}
        data-testid="pub-d-number"
        ref={focusRef}
        tabIndex={-1}
      >
        Manuscript number: {pubDNumber}
      </p>
      <p
        className={toggledClass}
        css={css._manuscriptDetailsItemCSS}
        data-testid="feeder-journal-title"
      >
        Previously submitted to <em>{feederJournalTitle}</em>
      </p>
      <Button
        aria-expanded={isToggledOpen}
        onClick={dispatchToggle}
        forwardCSS={css._manuscriptToggleButtonCSS}
        styleType="text-underlined"
        testId="toggle-manuscript-details"
      >
        {isToggledOpen ? "Hide" : "Show"} details
      </Button>
    </Box>
  );
};

export default ManuscriptInfo;
