export const EvaluationStatus = {
  NOT_STARTED: "NOT_STARTED",
  AWAITING_INITIATED_FROM_API: "AWAITING_INITIATED_FROM_API",
  INITIATED: "INITIATED",
  SUCCEEDED: "SUCCEEDED",
  FAILED: "FAILED",
} as const;
export type TEvaluationStatus =
  (typeof EvaluationStatus)[keyof typeof EvaluationStatus];

export const OrderedEvaluationStatus = {
  [EvaluationStatus.NOT_STARTED]: 0,
  [EvaluationStatus.AWAITING_INITIATED_FROM_API]: 1,
  [EvaluationStatus.INITIATED]: 2,
  [EvaluationStatus.SUCCEEDED]: 3,
  [EvaluationStatus.FAILED]: 4,
} as const;
export type TOrderedEvaluationStatus =
  (typeof OrderedEvaluationStatus)[keyof typeof OrderedEvaluationStatus];

export interface TEvaluationData {
  id: string;
  status: TEvaluationStatus;
  score: number | null;
}
