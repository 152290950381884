import React from "react";

import { aboutServicesLink, appTitle } from "../lib";

import { ExternalLink, HashLink, Logo, ToggleMenu, UtilBar } from "../generic";

import * as css from "./ApplicationBanner.css";
import SupportCenterLink from "../SupportCenterLink";

const ApplicationBanner = () => {
  const aboutLink = (
    <li>
      <ExternalLink
        analyticsRegion="ah-header"
        analyticsName="ah-about-service"
        href={aboutServicesLink}
        styleType="nav"
        testId="about-service-link"
      >
        About transfer service
      </ExternalLink>
    </li>
  );

  const supportLink = (
    <li>
      <SupportCenterLink
        analyticsRegion="ah-header"
        analyticsName="ah-help-and-support"
        styleType="nav"
        testId="support-center-header-link"
      />
    </li>
  );

  return (
    <header css={css._bannerContainerCSS} role="banner">
      <HashLink
        analyticsRegion="ah-header"
        analyticsName="ah-skip-to-content"
        forwardCSS={css._skipToContentCSS}
        href="#main-content"
        styleType="primary"
        testId="skip-to-content-link"
      >
        Skip to content
      </HashLink>
      <UtilBar>
        <UtilBar forwardCSS={css._logoTitleContainerCSS}>
          <div css={css._logoContainerCSS}>
            <Logo forwardCSS={css._logoCSS} testId="app-logo" />
          </div>
          <p css={css._appTitleCSS} id="app-title" data-testid="app-title">
            {appTitle}
          </p>
        </UtilBar>
        <ul css={css._desktopMenuCSS}>
          {aboutLink}
          {supportLink}
        </ul>
        <ToggleMenu forwardCSS={css._mobileMenuCSS}>
          <ul css={css._mobileMenuLinksCSS}>
            {aboutLink}
            {supportLink}
          </ul>
        </ToggleMenu>
      </UtilBar>
    </header>
  );
};

export default ApplicationBanner;
