import React from "react";

import { Box, Button, UtilBar } from "../generic";
import * as css from "./SuggestionsView.css";

export const DeclineAll = ({ onClick }: { onClick: () => void }) => {
  return (
    <>
      <Box
        element="section"
        forwardCSS={css._declineAllContainerCSS}
        variant="background"
      >
        <UtilBar>
          <div css={css._declineAllTextCSS}>
            <h2 css={css._declineAllHeadingCSS}>
              Nothing match your expectations?
            </h2>
            <p>
              You can decline all and we will unsubscribe you from this transfer
              offer.
            </p>
          </div>
          <Button
            onClick={onClick}
            styleType="secondary"
            testId="decline-all-button"
          >
            Decline all
          </Button>
        </UtilBar>
      </Box>
    </>
  );
};
