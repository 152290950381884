import React from "react";

import * as lib from "../lib";

import ErrorView from "../ErrorView";

import { EvaluationStatus, TEvaluationStatus } from "../lib";
import EvaluateConsent from "./EvaluateConsent";
import EvaluateFailed from "./EvaluateFailed";
import EvaluateInProgress from "./EvaluateInProgress";
import EvaluateSucceeded from "./EvaluateSucceeded";

export const getStatusViewTitle = ({
  text,
  manuscriptTitle,
}: {
  text: string;
  manuscriptTitle: string;
}) => {
  return `${text} for ${manuscriptTitle}`;
};

const EvaluationView = ({
  dispatchEvaluationStatusUpdate,
  hasTimeElapsed,
  evaluationData,
  submissionData,
}: {
  dispatchEvaluationStatusUpdate: React.Dispatch<TEvaluationStatus>;
  hasTimeElapsed: boolean;
  evaluationData: lib.TEvaluationData;
  submissionData: lib.TSubmissionData;
}) => {
  const focusRef = lib.useFocusRef<HTMLHeadingElement>({
    documentCookie: document.cookie,
    dependencies: [evaluationData.status],
    shouldFocus: true,
  });

  if (evaluationData.status === lib.EvaluationStatus.NOT_STARTED) {
    return (
      <EvaluateConsent
        ref={focusRef}
        dispatchEvaluationStatusUpdate={dispatchEvaluationStatusUpdate}
        submissionData={submissionData}
      />
    );
  } else if (
    (evaluationData.status === EvaluationStatus.AWAITING_INITIATED_FROM_API ||
      evaluationData.status === EvaluationStatus.INITIATED) &&
    !hasTimeElapsed
  ) {
    return <EvaluateInProgress submissionData={submissionData} />;
  } else if (evaluationData.status === lib.EvaluationStatus.SUCCEEDED) {
    return (
      <EvaluateSucceeded
        ref={focusRef}
        score={evaluationData.score}
        submissionData={submissionData}
      />
    );
  } else if (
    evaluationData.status === lib.EvaluationStatus.FAILED ||
    hasTimeElapsed
  ) {
    return <EvaluateFailed ref={focusRef} submissionData={submissionData} />;
  }

  return (
    <ErrorView
      errorType={lib.AppErrorType.JAVASCRIPT_ERROR}
      submissionData={submissionData}
    />
  );
};

export default EvaluationView;
