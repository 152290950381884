import { TFeederJournalData, TSuggestedJournalData } from "./JournalData.types";
import { TSubmissionData } from "./SubmissionData.types";

export type TTrackingData = Pick<
  TSubmissionData,
  "documentId" | "feederJournal" | "offerType" | "revision" | "title"
> & {
  aaPageName: string;
  currentJournal: Pick<
    TFeederJournalData | TSuggestedJournalData,
    "issn" | "title"
  >;
};
