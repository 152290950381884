import { useQueryClient, useMutation } from "@tanstack/react-query";
import { apiClient } from "../apiClient";

export const useConfirmTransfer = ({
  submissionUuid,
  acronym,
  onMutate,
  onError,
}: {
  submissionUuid: string;
  acronym: string;
  onMutate: () => void;
  onError: (error: unknown) => void;
}) => {
  const queryClient = useQueryClient();

  const result = useMutation({
    mutationFn: () => {
      return apiClient({
        method: "post",
        url: `/submissions/confirm/${submissionUuid}/${acronym}`,
      });
    },
    onMutate: async () => {
      onMutate();
      queryClient.cancelQueries({
        queryKey: ["submissionData", submissionUuid],
      });
    },
    onSettled: () => {
      // Mark submission data query as stale but don't refetch per se
      // Is react-query still refetching too soon? Check the query client settings in the App component.
      queryClient.invalidateQueries({
        queryKey: ["submissionData", submissionUuid],
      });
    },
    onError: (error: unknown) => {
      onError(error);
      queryClient.cancelQueries({
        queryKey: ["submissionData", submissionUuid],
      });
    },
  });

  return result;
};
