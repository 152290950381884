import React from "react";

export const PendoErrorExplainer = ({ errorCode }: { errorCode: number }) => {
  return (
    <div
      data-testid="pendo-error-explainer"
      data-pendoid="error-explainer"
      data-pendo-error={`pendo-${errorCode}-error`}
    >
      &nbsp;
    </div>
  );
};
