import { css } from "@emotion/react";

/* For screen readers */
export const _srOnlyCSS = css`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  * + &,
  & + * {
    margin-top: 0;
  }
`;
