import { css } from "@emotion/react";
import { _utilBarContainerCSS } from "../generic/UtilBar/UtilBar.css";

export const _offerTypeContainerCSS = css`
  ${_utilBarContainerCSS}
  display: inline-flex;
  gap: var(--row-gap) var(--column-gap);
`;

export const _offerTypeDefaultBadgeCSS = css`
  background: rgb(var(--es-color-secondary-very-pale-blue-rgb));
  color: rgb(var(--es-color-tertiary-blue3-rgb));
`;

export const _offerTypeSMEBadgeCSS = css`
  background: var(--color-alt-info-light);
  color: rgb(var(--es-color-tertiary-purple3-rgb));
`;
