import React, { useEffect, useReducer, useState } from "react";
import { useOutletContext } from "react-router-dom";

import {
  AppErrorType,
  TViewContextProps,
  handleApiError,
  isOfferStatusTransferInitiated,
  isOfferStatusTransferFailed,
  isOfferStatusTransferSucceeded,
  isOfferStatusAwaitingTransferInitiatedFromApi,
  ExperimentGroup,
} from "../lib";

import ErrorView from "../ErrorView";
import { useEvaluationData } from "../lib/useEvaluationData";
import {
  EvaluationStatus,
  TEvaluationData,
} from "../lib/types/Evaluation.types";
import { LoadingIndicator } from "../generic";
import { reduceEvaluationStatusUpdate } from "./EvaluationView.utils";
import EvaluateView from "./EvaluateView";

const EvaluateViewRouter = () => {
  const { submissionData } = useOutletContext<TViewContextProps>();

  const [hasTimeElapsed, setHasTimeElapsed] = useState(false);

  const {
    languageExperiment: languageExperiment,
    status: offerStatus,
    submissionUuid,
  } = submissionData;

  const [evaluationStatus, dispatchEvaluationStatusUpdate] = useReducer(
    reduceEvaluationStatusUpdate,
    EvaluationStatus.NOT_STARTED
  );

  const shouldPoll =
    evaluationStatus === EvaluationStatus.AWAITING_INITIATED_FROM_API ||
    evaluationStatus === EvaluationStatus.INITIATED;

  const {
    isLoading,
    data: rawEvaluationData,
    error,
  } = useEvaluationData({
    languageExperiment,
    offerStatus,
    submissionUuid,
    shouldPoll: shouldPoll && !hasTimeElapsed,
  });

  const evaluationData = {
    ...rawEvaluationData?.data,
    status: evaluationStatus,
  } as TEvaluationData;

  useEffect(() => {
    if (!shouldPoll || hasTimeElapsed) return;

    const timeoutId = setTimeout(
      () => {
        setHasTimeElapsed(true);
      },
      10 * 1000 * 60 // 10 minutes
    );

    return () => clearTimeout(timeoutId);
  }, [shouldPoll, hasTimeElapsed]);

  useEffect(() => {
    if (rawEvaluationData) {
      dispatchEvaluationStatusUpdate(rawEvaluationData?.data.status);
    }
  }, [rawEvaluationData]);

  if (
    languageExperiment !== ExperimentGroup.EXPERIMENT ||
    (!isOfferStatusAwaitingTransferInitiatedFromApi(offerStatus) &&
      !isOfferStatusTransferInitiated(offerStatus) &&
      !isOfferStatusTransferSucceeded(offerStatus) &&
      !isOfferStatusTransferFailed(offerStatus))
  ) {
    return (
      <ErrorView
        errorType={AppErrorType.INVALID_URL}
        submissionData={submissionData}
      />
    );
  }

  if (isLoading) {
    return <LoadingIndicator />;
  } else if (evaluationData) {
    return (
      <EvaluateView
        dispatchEvaluationStatusUpdate={dispatchEvaluationStatusUpdate}
        hasTimeElapsed={hasTimeElapsed}
        evaluationData={evaluationData}
        submissionData={submissionData}
      />
    );
  }

  const { errorType, errorDetails } = handleApiError(error);
  return (
    <ErrorView
      errorType={errorType}
      errorDetails={errorDetails}
      submissionData={submissionData}
    />
  );
};

export default EvaluateViewRouter;
