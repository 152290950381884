import React, { useRef, useState } from "react";

import { ToggleMenuButton } from "./ToggleMenuButton";

import { toggleMenuDialogCSS } from "./ToggleMenu.css";
import { TMergeElementProps, useScrollBlock } from "../../lib";

export const ToggleMenu = ({
  children,
  forwardCSS,
}: TMergeElementProps<
  "div",
  {
    children: React.ReactNode | React.ReactNode[];
  }
>) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [blockScroll, allowScroll] = useScrollBlock();

  return (
    <>
      <ToggleMenuButton
        aria-label="Open menu"
        forwardCSS={forwardCSS}
        iconName="menu"
        onClick={() => {
          blockScroll();
          setIsOpen(true);
          if (dialogRef.current) {
            dialogRef.current.showModal();
          }
        }}
        testId="toggle-menu-open-button"
      />
      <dialog css={[toggleMenuDialogCSS(isOpen), forwardCSS]} ref={dialogRef}>
        <div className="toggle-menu-content">
          <form method="dialog" css="toggle-menu-close-form">
            <ToggleMenuButton
              aria-label="Close menu"
              iconName="delete"
              onClick={() => {
                setTimeout(() => {
                  setIsOpen(false);
                }, 0);
                setTimeout(() => {
                  if (dialogRef.current) {
                    dialogRef.current.close();
                  }
                  allowScroll();
                }, 300);
              }}
              testId="toggle-menu-close-button"
            />
          </form>
          {children}
        </div>
      </dialog>
    </>
  );
};
