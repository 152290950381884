import React from "react";

import { ExternalLink } from "../generic";
import { TAnalyticsRegion, TForwardCSS } from "../lib";

const CASInfoLink = ({
  analyticsRegion,
  descriptionId,
  forwardCSS,
}: {
  analyticsRegion: TAnalyticsRegion;
  descriptionId?: string;
  forwardCSS?: TForwardCSS;
}) => {
  return (
    <ExternalLink
      analyticsRegion={analyticsRegion}
      analyticsName="ah-goto-about-cas-ranking"
      aria-describedby={descriptionId}
      forwardCSS={forwardCSS}
      href="https://www.fenqubiao.com/"
      styleType="text"
      testId="cas-info-link"
    >
      About CAS Journal Rankings
    </ExternalLink>
  );
};

export default CASInfoLink;
