import { css } from "@emotion/react";

import { _maxMediaCSS, _minMediaCSS } from "../lib";

export const _manuscriptInfoContainerCSS = css`
  margin-top: var(--row-gap);
  --margin: var(--spacing-xs);
`;

// https://css-tricks.com/almanac/properties/l/line-clamp/
export const _manuscriptTitleCSS = css`
  font-family: Gulliver, sans-serif;
  font-size: var(--font-size-l);
  ${_maxMediaCSS("s")} {
    &.closed {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
`;

export const _manuscriptDetailsItemCSS = css`
  font-size: var(--font-size-s);
  color: var(--color-on-surface-dim);
  ${_maxMediaCSS("s")} {
    &.closed {
      display: none;
    }
  }
`;

export const _manuscriptToggleButtonCSS = css`
  ${_minMediaCSS("s")} {
    display: none;
  }
`;
