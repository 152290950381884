import React, { ReactNode } from "react";

import {
  _srOnlyCSS,
  getOpenAccessInfoUrl,
  TAnalyticsRegion,
  TSuggestedJournalData,
  useFocusRef,
} from "../lib";
import { ExternalLink, Box, HorizonalRule, Icon, UtilBar } from "../generic";
import * as css from "./JournalCard.css";

import CASInfoLink from "../CASInfo/CASInfoLink";
import { JournalCASMetrics, JournalStatsMetrics } from "../JournalMetrics";
import JournalCover from "../JournalCover";
import OpenAccessInfo from "../OpenAccessInfo";

interface TJournalCard {
  analyticsRegion: TAnalyticsRegion;
  cta?: ReactNode;
  journal: TSuggestedJournalData;
  level?: 1 | 2 | 3;
  shouldDescribe?: boolean;
}

export const getJournalCardTitleId = (cardId: number | "promoted") =>
  `journal-title-${cardId}`;

const JournalCard = ({
  analyticsRegion,
  cta,
  journal,
  level = 2,
  shouldDescribe,
}: TJournalCard) => {
  const focusRef = useFocusRef<HTMLHeadingElement | HTMLParagraphElement>({
    documentCookie: document.cookie,
    dependencies: [journal.title],
    shouldFocus: true,
  });

  const cardId = journal.isGoldenOpenAccess ? "promoted" : journal.ranking;
  const gprTitleId = `guaranteed-peer-review-title-${cardId}`;
  const mainTitleId = getJournalCardTitleId(cardId);
  const descriptionId = shouldDescribe ? mainTitleId : undefined;

  const MainTitle = level === 1 ? "h1" : level === 2 ? "h2" : "h3";
  const SubTitle = level === 1 ? "h2" : level === 2 ? "h3" : "h4";

  const divider = (
    <HorizonalRule
      spacing="none"
      forwardCSS={css._journalCardContentCSS({ size: "l" })}
    />
  );

  const cover = (
    <div css={css._journalCardCoverCSS}>
      <JournalCover issn={journal.issn} />
    </div>
  );

  const heading = (
    <>
      <MainTitle
        aria-describedby={
          journal.isGuaranteedPeerReview ? gprTitleId : undefined
        }
        css={[
          css._journalCardContentCSS({ size: "s" }),
          css._journalCardMainTitleCSS,
        ]}
        data-testid={mainTitleId}
        id={mainTitleId}
        ref={level === 1 ? focusRef : undefined}
        tabIndex={level === 1 ? -1 : undefined}
      >
        {journal.title}
      </MainTitle>
      {cta && <div css={css._journalCardCTACSS({ size: "s" })}>{cta}</div>}
    </>
  );

  const casInfo = journal.casInformation ? (
    <>
      <JournalCASMetrics
        forwardCSS={css._journalCardContentCSS({ size: "m" })}
        casInformation={journal.casInformation}
      />
      <div css={css._journalCardCTACSS({ size: "m" })}>
        <CASInfoLink
          descriptionId={descriptionId}
          analyticsRegion={analyticsRegion}
        />
      </div>
      {divider}
    </>
  ) : null;

  const metrics = (
    <JournalStatsMetrics
      analyticsRegion={analyticsRegion}
      forwardCSS={css._journalCardContentCSS({ hasSubGrid: true, size: "m" })}
      impactFactor={journal.impactFactor}
      citeScore={journal.citeScore}
      timeToFirstDecision={journal.timeToFirstDecision}
      timeToAcceptance={journal.timeToAcceptance}
      acceptanceRate={journal.acceptanceRate}
      ranking={journal.ranking}
    />
  );

  const openAccessInfo = (
    <>
      <OpenAccessInfo
        apcWaiver={journal.apcWaiver}
        forwardCSS={css._journalCardContentCSS({ hasSubGrid: true, size: "m" })}
        openAccessType={journal.openAccessType}
        ranking={journal.ranking}
      />
      <div css={css._journalCardCTACSS({ size: "m" })}>
        <ExternalLink
          analyticsRegion={analyticsRegion}
          analyticsName="ah-read-about-oa-and-apc"
          aria-describedby={descriptionId}
          href={getOpenAccessInfoUrl(journal.title)}
          styleType="text"
          testId="open-access-link"
        >
          About publishing options
        </ExternalLink>
      </div>
    </>
  );

  const baseCard = (
    <div css={css._journalCardGridCSS}>
      {cover}
      {heading}
      {divider}
      <SubTitle css={_srOnlyCSS}>Insights</SubTitle>
      {casInfo}
      {metrics}
      {divider}
      <SubTitle css={_srOnlyCSS}>Publishing options</SubTitle>
      {openAccessInfo}
    </div>
  );

  if (journal.isGuaranteedPeerReview) {
    return (
      <>
        <Box
          color="info"
          variant="highlight"
          element="section"
          topper={
            <UtilBar forwardCSS={css._journalCardGPR}>
              <Icon name="check-clipboard" />
              <p data-testid={gprTitleId}>
                <strong>
                  {journal.title}{" "}
                  {/* Don't repeat journal title in accessible description */}
                  <span id={gprTitleId}>
                    guarantees peer review of your transferred manuscript
                  </span>
                  .
                </strong>
              </p>
            </UtilBar>
          }
        >
          {baseCard}
        </Box>
        <HorizonalRule variant="loop" spacing="none" />
      </>
    );
  }

  return (
    <>
      <Box element="section">{baseCard}</Box>
      <HorizonalRule variant="loop" spacing="none" />
    </>
  );
};

export default JournalCard;
