import React from "react";

import { journalCoverCSS } from "./JournalCover.css";
import { TMergeElementProps } from "../lib";

const JournalCover = ({
  alt,
  forwardCSS,
  issn,
  testId,
}: TMergeElementProps<
  "img",
  {
    alt?: string;
    issn: string;
  }
>) => {
  const filename = issn.replace("-", "");

  const src = `${process.env.JOURNAL_IMAGE_ENDPOINT}/${filename}.jpg`;

  /* 
    Leaving alt attribute empty by default because...
    1. The journal cover usually serves a decorative function in this application.
    2. The only alt attribute we could provide, the journal title, is already provided in other parts of the ui.
*/

  return (
    <img
      alt={alt || ""}
      data-testid={testId}
      css={[journalCoverCSS, forwardCSS]}
      src={src}
    />
  );
};

export default JournalCover;
