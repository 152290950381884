import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { apiClient } from "..";
import { EvaluationStatus, TEvaluationStatus } from "../types";

export const useEvaluationDownload = ({
  evaluationStatus,
  submissionUuid,
  shouldFetchDownloadUrl
}: {
  evaluationStatus: TEvaluationStatus;
  submissionUuid: string;
  shouldFetchDownloadUrl: boolean;
}): UseQueryResult<AxiosResponse<{ url: string }, unknown>, Error> => {
  const hasCorrectEvaluationStatus = evaluationStatus === EvaluationStatus.SUCCEEDED

  const enabled = (
    !!submissionUuid &&
    !!hasCorrectEvaluationStatus &&
    !!shouldFetchDownloadUrl
  )

  return useQuery({
    queryKey: ["language-editing-download", submissionUuid],
    queryFn: async () => {
      const response = await apiClient({
        url: `/submissions/${submissionUuid}/language-editing/download`,
      });

      return response;
    },
    staleTime: 300000, // Download link is valid for 5 minutes
    enabled,
  });
};
