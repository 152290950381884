import React, { useRef } from "react";

import * as lib from "../lib";

import {
  Box,
  ExternalLink,
  HorizonalRule,
  ListOfTerms,
  UtilBar,
} from "../generic";

import JournalScope from "../JournalScope";
import { TransferButton } from "./TransferButton";

import * as css from "./DetailView.css";
import CASInfo, { CASInfoLink } from "../CASInfo";
import JournalCard from "../JournalCard";
import { emdash } from "../lib";

const DetailView = ({
  dispatchOfferStatusUpdate,
  suggestedJournal,
}: {
  dispatchOfferStatusUpdate: lib.TViewContextProps["dispatchOfferStatusUpdate"];
  offerType: lib.TOfferType;
  suggestedJournal: lib.TSuggestedJournalData;
}) => {
  const initiatedDateRef = useRef<string>(lib.formatDate(Date.now()));

  const onTransferButtonClick = () => {
    lib.trackMenuButtonClick("transferButton");
    dispatchOfferStatusUpdate({
      action: lib.StatusUpdateTypes.OPEN_CONFIRM_TRANSFER_FORM,
      transfer: {
        initiatedDate: initiatedDateRef.current,
        targetJournal: suggestedJournal,
      },
    });
  };

  return (
    <article>
      <JournalCard
        analyticsRegion={"ah-journal-data"}
        cta={
          <TransferButton
            onClick={onTransferButtonClick}
            testId="transfer-button"
          />
        }
        journal={suggestedJournal}
        level={1}
      />
      {suggestedJournal.isGuaranteedPeerReview && (
        <>
          <Box forwardCSS={css._detailSectionCSS} element="section">
            <h2 css={css._detailSubtitleCSS}>Peer review guaranteed option</h2>
            <p>
              If you choose to transfer your work to this journal an Elsevier
              Scientific Managing Editor will guide your manuscript through peer
              review.
            </p>
            <p>
              Your transferred manuscript will undergo normal peer review to
              determine whether it is suitable for publication in the new
              journal.
            </p>
          </Box>
          <HorizonalRule />
        </>
      )}
      {suggestedJournal.casInformation && (
        <>
          <Box forwardCSS={css._detailSectionCSS} element="section">
            <h2 css={css._detailSubtitleCSS}>CAS Journal Ranking</h2>
            <CASInfo
              casInformation={suggestedJournal.casInformation}
              link={<CASInfoLink analyticsRegion="ah-journal-data" />}
            />
          </Box>
          <HorizonalRule />
        </>
      )}
      <Box forwardCSS={css._detailSectionCSS} element="section">
        <h2 css={css._detailSubtitleCSS}>Issn</h2>
        <p>{suggestedJournal.issn}</p>
      </Box>
      {(suggestedJournal.editorInChief || suggestedJournal.editorialBoard) && (
        <Box forwardCSS={css._detailSectionCSS} element="section">
          <h2 css={css._detailSubtitleCSS}>Editorial Board</h2>
          <p>
            {suggestedJournal.editorInChief && (
              <span data-testid="editor-in-chief">
                <span css={css._detailItemCSS}>Editor-in-Chief:</span>{" "}
                <span>{suggestedJournal.editorInChief}</span>
              </span>
            )}
            {suggestedJournal.editorInChief &&
              suggestedJournal.editorialBoard &&
              ` ${emdash} `}
            {suggestedJournal.editorialBoard && (
              <ExternalLink
                analyticsName="ah-editorial-board"
                analyticsRegion="ah-journal-data"
                href={suggestedJournal.editorialBoard}
                styleType="text"
                testId="editorial-board-link"
              >
                Editorial Board
              </ExternalLink>
            )}
          </p>
        </Box>
      )}
      {suggestedJournal.subjectAreas && (
        <Box forwardCSS={css._detailSectionCSS} element="section">
          <h2 css={css._detailSubtitleCSS}>Subject Areas</h2>
          <ListOfTerms
            testId="subject-areas"
            terms={suggestedJournal.subjectAreas}
          />
        </Box>
      )}
      {suggestedJournal.matchingKeywords && (
        <Box forwardCSS={css._detailSectionCSS} element="section">
          <h2 css={css._detailSubtitleCSS}>
            Top matching keywords with your submission
          </h2>
          <ListOfTerms
            testId="matching-keywords"
            terms={suggestedJournal.matchingKeywords}
          />
        </Box>
      )}
      {suggestedJournal.scope && (
        <Box forwardCSS={css._detailSectionCSS} element="section">
          <h2 css={css._detailSubtitleCSS}>Journal Scope</h2>
          <JournalScope scope={suggestedJournal.scope} />
        </Box>
      )}
      <Box forwardCSS={css._detailSectionCSS} element="section">
        <UtilBar>
          <ExternalLink
            analyticsName="ah-read-about-journal"
            analyticsRegion="ah-journal-data"
            href={lib.getJournalHomePageUrl(suggestedJournal.title)}
            styleType="secondary"
            testId="read-about-journal-link"
          >
            Read more about this journal
          </ExternalLink>
          <TransferButton
            onClick={() => {
              dispatchOfferStatusUpdate({
                action: lib.StatusUpdateTypes.OPEN_CONFIRM_TRANSFER_FORM,
                transfer: {
                  initiatedDate: initiatedDateRef.current,
                  targetJournal: suggestedJournal,
                },
              });
              lib.trackMenuButtonClick("transferButtonBottom");
            }}
            testId="transfer-button-bottom"
          />
        </UtilBar>
      </Box>
    </article>
  );
};

export default DetailView;
