import { useQuery } from "@tanstack/react-query";

import { apiClient } from "..";

export const useSubmissionDataFromQueryParams = (queryParams: {
  acceptLink: string;
  journal: string;
  manuscriptId: string;
}) => {
  return useQuery({
    // Large query key ["submissionUuid", ...] is needed to prevent caching in react-query, which is a big problem in tests.
    queryKey: [
      "submissionUuid",
      queryParams?.manuscriptId,
      queryParams?.journal,
      queryParams?.acceptLink,
    ],
    queryFn: async () => {
      const response = await apiClient({
        method: "put",
        url: `/submissions`,
        data: queryParams,
      });

      return response;
    },
    enabled: !!queryParams, // Don't try to query the api unless valid query params are available
  });
};
