import { css } from "@emotion/react";

export const _casInfoCSS = css`
  font-size: var(--font-size-s);
`;

export const casOuterGrid = css`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: var(--column-gap);
`;

export const casInnerGrid = css`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-gap: calc(var(--column-gap) / 3);
  & + & {
    margin-top: var(--row-gap);
  }
`;

export const casHeading = css`
  font-size: inherit;
  color: var(--color-on-surface-dim);
`;

export const casSeparator = css`
  grid-column: 1 / -1;
  margin-top: 0;
`;

export const casLeaderLine = css`
  overflow: hidden;
  text-wrap: wrap;
  height: var(--line-height);
`;
