import React from "react";

import * as css from "./CASInfo.css";

import { reactKey, TCASInformation } from "../lib";
import { Tooltip } from "../generic";

const Separator = () => {
  return (
    <span css={css.casLeaderLine} aria-hidden="true">
      {[...Array(6)].map(
        () => ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . "
      )}
      .
    </span>
  );
};

const CASInfo = ({
  casInformation,
  link,
}: {
  casInformation: TCASInformation;
  link: React.ReactNode;
}) => {
  return (
    <div css={css._casInfoCSS}>
      <div css={css.casOuterGrid}>
        <h3 css={css.casHeading}>Major</h3>
        <div css={css.casInnerGrid}>
          <p>{casInformation.majorRanking.name}</p>
          <Separator />
          <p>Tier&nbsp;{casInformation.majorRanking.tier}</p>
        </div>
        <Tooltip
          forwardCSS={css.casHeading}
          label="Top journal"
          id="cas-top-journal"
        >
          <p>
            <strong>CAS Top Journal</strong>
          </p>
          <p>
            The Top Journal indicator highlights journals with high-level
            citation impact and large publication volumes. Selection rules:
          </p>
          <ul>
            <li>Tier-1 journals.</li>
            <li>
              The top 10% of Tier-2 journals based on the total number of
              citations over the past two years.
            </li>
          </ul>
        </Tooltip>
        <p>{casInformation.majorRanking.top ? "Yes" : "No"}</p>
        <hr css={css.casSeparator} />
        <h3 css={css.casHeading}>Minors</h3>
        <div>
          {casInformation.minorRanking.map((minorRanking) => (
            <div css={css.casInnerGrid} key={reactKey(minorRanking)}>
              <p>{minorRanking.name}</p>
              <Separator />
              <p>Tier&nbsp;{minorRanking.tier}</p>
            </div>
          ))}
        </div>
      </div>
      <p>{link}</p>
    </div>
  );
};

export default CASInfo;
