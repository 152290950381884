import React, { forwardRef } from "react";

import * as lib from "../lib";
import { Box, Button, UtilBar } from "../generic";
import * as css from "./StatusView.css";
import { TTransferData } from "../lib";
import {
  statusMessageTitleProps,
  statusMessageContentProps,
} from "./StatusView.utils";
import JournalCard from "../JournalCard";
import ViewContainer from "../ViewContainer";

const ConfirmTransferForm = forwardRef(
  (
    {
      dispatchOfferStatusUpdate,
      submissionData,
    }: {
      dispatchOfferStatusUpdate: lib.TViewContextProps["dispatchOfferStatusUpdate"];
      submissionData: lib.TSubmissionData & {
        transfer: TTransferData;
      };
    },
    ref: React.Ref<HTMLHeadingElement>
  ) => {
    const { submissionUuid, title: manuscriptTitle, transfer } = submissionData;

    const onCancelTransfer = () => {
      lib.trackFormButtonClick("cancelTransfer");
      dispatchOfferStatusUpdate({
        action: lib.StatusUpdateTypes.CANCEL,
      });
    };

    const confirmTransfer = lib.useConfirmTransfer({
      submissionUuid,
      acronym: transfer.targetJournal.acronym,
      onMutate: () => {
        lib.trackFormButtonClick("confirmTransfer");
        dispatchOfferStatusUpdate({
          action: lib.StatusUpdateTypes.INITIATE_TRANSFER,
          transfer,
        });
      },
      onError: (error: unknown) => {
        dispatchOfferStatusUpdate({
          action: lib.StatusUpdateTypes.THROW_TRANSFER_ERROR,
          transfer,
        });

        const handledApiError = lib.handleApiError(error);
        lib.trackApiError({
          action: "transfer_confirm",
          status: handledApiError.errorDetails || "unknown",
        });
      },
    });

    const onConfirmTransfer = () => confirmTransfer.mutate();

    return (
      <ViewContainer
        viewName="status"
        htmlTitle={`Confirm transfer for ${manuscriptTitle}?`}
        topTitle="Confirm transfer for"
        submissionData={submissionData}
      >
        <Box>
          <h1 {...statusMessageTitleProps} ref={ref}>
            Transfer your manuscript to <em>{transfer.targetJournal.title}?</em>
          </h1>
          <p {...statusMessageContentProps}>
            We will transfer your files to{" "}
            <em>{transfer.targetJournal.title}</em>. You will receive an email
            from them with information about completing your submission. You
            will also be able to make revisions.
          </p>
        </Box>
        <JournalCard
          analyticsRegion="ah-status"
          journal={transfer.targetJournal}
        />
        <Box forwardCSS={css._transferSummaryButtonsCSS}>
          <UtilBar>
            <Button
              onClick={onCancelTransfer}
              styleType="secondary"
              testId="cancel-transfer-button"
            >
              Cancel
            </Button>
            <Button
              onClick={onConfirmTransfer}
              testId="confirm-transfer-button"
            >
              Confirm transfer
            </Button>
          </UtilBar>
        </Box>
      </ViewContainer>
    );
  }
);

export default ConfirmTransferForm;
