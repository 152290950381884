import React from "react";

import { ButtonLinkInside } from "./BLInside";
import { buttonLinkCSS } from "./BL.css";
import { TButton } from "./BL.types";

export const Button = React.forwardRef(
  (
    {
      children,
      className: propsClassName,
      onClick,
      forwardCSS,
      icon,
      styleType = "primary",
      type,
      testId,
      ...rest
    }: TButton,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const classNames: string[] = [];

    classNames.push(`button button-${styleType}`);

    if (propsClassName) {
      classNames.push(propsClassName);
    }

    return (
      <button
        {...rest}
        className={classNames.join(" ")}
        css={[buttonLinkCSS({ styleType }), forwardCSS]}
        data-testid={testId}
        onClick={onClick}
        ref={ref}
        type={type || "button"}
      >
        <ButtonLinkInside icon={icon}>{children}</ButtonLinkInside>
      </button>
    );
  }
);
