import { css } from "@emotion/react";

export const caretCSS = css`
  // TODO: maybe global style should not include svg instead
  --margin: 0;
  & path:nth-of-type(1) {
    fill: var(--color-surface);
  }
  & path:nth-of-type(2) {
    fill: var(--color-border);
  }
  & path:nth-of-type(3) {
    fill: var(--color-brand);
  }
  display: block;
  position: absolute;

  // Positioning
  .positionx-right & {
    left: -1px;
  }
  .positionx-left & {
    right: -1px;
  }
  .positiony-bottom & {
    bottom: 100%;
  }
  .positiony-top & {
    top: 100%;
  }
  .positionx-right.positiony-bottom & {
    transform: rotateY(180deg) rotateZ(180deg);
  }
  .positionx-left.positiony-bottom & {
    transform: rotateZ(180deg);
  }
  .positionx-left.positiony-top & {
    transform: rotateY(180deg);
  }
  // right-top is default rotation
  // End of Positioning
`;

export const tooltipContainerCSS = css`
  display: inline-block;
  position: relative;

  .tooltip-button {
    --color-interaction: var(--color-secondary);
    --color-underline: var(--color-secondary);
  }

  .tooltip-content-outer {
    position: absolute;
    white-space: normal;
    width: 20em;
    max-width: 80vw;
    display: block;

    /* Toggle the dialog */
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in;
    /* End of Toggle */

    // Invisible space for hover to allow mouse movement from target to content
    &:before {
      content: "";
      display: block;
      background: transparent;
      position: absolute;
      top: -1rem;
      right: -1rem;
      bottom: -1rem;
      left: -1rem;
    }

    .tooltip-content-inner {
      --color-interaction: var(--color-on-surface);
      --color-underline: var(--color-on-surface);
      position: relative;
      border: 1px solid var(--color-border);
      padding: 1rem;
      background: var(--color-surface);
      color: var(--color-on-surface);
    }
  }

  // Toggle visibility
  &:hover .tooltip-content-outer,
  &:focus-within .tooltip-content-outer,
  .tooltip-button:focus + .tooltip-content-outer {
    visibility: visible;
    opacity: 1;
  }
  // End of toggle visibility

  // Positioning
  &.positionx-right .tooltip-content-outer {
    left: 1.5rem;
  }
  &.positionx-left .tooltip-content-outer {
    right: 1.5rem;
  }
  &.positiony-bottom .tooltip-content-outer {
    top: calc(100% + 1rem);
    .tooltip-content-inner {
      border-top-color: var(--color-brand);
      border-top-width: 2px;
    }
  }
  &.positiony-top .tooltip-content-outer {
    bottom: calc(100% + 1rem);
    .tooltip-content-inner {
      border-bottom-color: var(--color-brand);
      border-bottom-width: 2px;
    }
  }
  // End of Positioning
`;
