import React, { forwardRef } from "react";

import {
  TViewContextProps,
  trackFormButtonClick,
  StatusUpdateTypes,
  useDeclineAll,
  handleApiError,
  trackApiError,
  TSubmissionData,
} from "../lib";
import { Box, Button, UtilBar } from "../generic";
import {
  statusMessageTitleProps,
  statusMessageContentProps,
  AssistanceMessage,
} from "./StatusView.utils";
import * as css from "./StatusView.css";
import ViewContainer from "../ViewContainer";

const ConfirmDeclineForm = forwardRef(
  (
    {
      dispatchOfferStatusUpdate,
      submissionData,
    }: {
      dispatchOfferStatusUpdate: TViewContextProps["dispatchOfferStatusUpdate"];
      submissionData: TSubmissionData & {
        declinedOn: string;
      };
    },
    ref: React.Ref<HTMLHeadingElement>
  ) => {
    const { declinedOn, submissionUuid } = submissionData;

    const onCancel = () => {
      trackFormButtonClick("cancelDeclineAll");
      dispatchOfferStatusUpdate({
        action: StatusUpdateTypes.CANCEL,
      });
    };

    const declineAll = useDeclineAll({
      submissionUuid,
      onMutate: () => {
        trackFormButtonClick("confirmDeclineAll");
        dispatchOfferStatusUpdate({
          action: StatusUpdateTypes.INITIATE_DECLINE,
          declinedOn,
        });
      },
    });

    const onDeclineAll = () => {
      declineAll.mutate(
        void {
          onError: (error: unknown) => {
            const handledApiError = handleApiError(error);
            trackApiError({
              action: "decline_all_confirm",
              status: handledApiError.errorDetails || "unknown",
            });
          },
        }
      );
    };

    return (
      <ViewContainer
        viewName="status"
        htmlTitle="Decline all transfer suggestions for your manuscript?"
        topTitle="Decline suggestions for"
        submissionData={submissionData}
      >
        <Box>
          <h1 {...statusMessageTitleProps} ref={ref}>
            Decline all transfer suggestions for your manuscript?
          </h1>
          <p {...statusMessageContentProps}>
            You will no longer be able to view the transfer suggestions and you
            will no longer recieve email reminders for this transfer offer.
          </p>
          <AssistanceMessage />
        </Box>
        <Box forwardCSS={css._transferSummaryButtonsCSS}>
          <UtilBar>
            <Button
              onClick={onCancel}
              styleType="secondary"
              testId="cancel-decline-button"
            >
              Cancel
            </Button>
            <Button onClick={onDeclineAll} testId="confirm-decline-button">
              Confirm
            </Button>
          </UtilBar>
        </Box>
      </ViewContainer>
    );
  }
);

export default ConfirmDeclineForm;
