import { css } from "@emotion/react";
import { _minMediaCSS } from "../lib";

export const journalCoverCSS = css`
  display: block;
  max-width: calc(80 / 16 * 1rem);
  aspect-ratio: auto 3/4;
  object-fit: contain;
  border: 1px solid var(--color-border);

  ${_minMediaCSS("s")} {
    max-width: calc(90 / 16 * 1rem);
  }
`;
