export const testMachineSuggestions = [
  "CEMENT",
  "ARRAY",
  "GACETA",
  "JCR",
  "AGRO",
  "LEAD",
];

export const testEditorSuggestions = [
  "CEMENT",
  "ARRAY",
  "GACETA",
  "JCR",
  "DRUDIS",
  "AGRO",
  "LEAD",
];
