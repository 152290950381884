import React from "react";

import { TMergeElementProps } from "../../lib";

import { _betaBadgeCSS } from "./BetaBadge.css";

export const BetaBadge = ({
  forwardCSS,
  testId,
  ...rest
}: TMergeElementProps<
  "span",
  {
    children?: undefined;
  }
>) => (
  <span css={[_betaBadgeCSS, forwardCSS]} data-testid={testId} {...rest}>
    Beta
  </span>
);
