import React, { ReactNode } from "react";

/**
 * Any error thrown by javascript that isn't handled will be caught by the error boundary
 */

interface TErrorBoundaryProps {
  children: ReactNode | ReactNode[];
  fallback?: ReactNode | ReactNode[];
}

class ErrorBoundary extends React.Component<
  TErrorBoundaryProps,
  { hasError: boolean }
> {
  constructor(props: TErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: unknown) {
    console.log("ERROR", error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: unknown, info: unknown) {
    console.log("ERROR", error, info);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
