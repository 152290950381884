import { TAppError, TTrackingData } from "..";

import {
  aaErrorCodes,
  aaManuscriptId,
  aaOfferType,
  aaTruncate,
  readyStateDeferrer,
} from "./tracking.utils";

/*
  Adobe analytics is bound to window on page init,
  if not there for any reason, ensure graceful page load anyway
*/

window.adobe = window?.adobe || {
  target: {
    triggerView: () => {
      /* Do nothing */
    },
  },
};

window.pageDataTracker = window?.pageDataTracker || {
  trackEvent: () => {
    /* Do nothing */
  },
  trackPageLoad: () => {
    /* Do nothing */
  },
};

window.s = window?.s || {
  visitor: {
    appendVisitorIDsTo: (url: string) => {
      return url;
    },
  },
};

export const activateTarget = (pageName: string) => {
  if (window.adobe?.target?.triggerView) {
    window.adobe?.target?.triggerView(pageName);
  }
};

const initialPageData = () => {
  const payload = {
    page: {
      environment: process.env.AA_ENVIRONMENT,
      businessUnit: "ELS:RP:ST",
      productName: "AH",
      name: "page-init",
      language: "en",
      type: "NP-LD",
    },
    visitor: {
      accessType: "ah:ANON_GUEST",
    },
  };

  // console.log("INITIAL LOAD", payload);

  return payload;
};

export const trackInitialLoad = () => {
  window.pageData = initialPageData();
  window.pageDataTracker?.trackPageLoad();
};

export function trackNavigation({
  aaPageName,
  documentId,
  feederJournal,
  currentJournal,
  offerType,
  revision,
  title: manuscriptTitle,
}: TTrackingData) {
  const journalData = currentJournal || feederJournal;

  const payload = {
    page: {
      name: `${aaOfferType(offerType)}:${aaPageName}`,
      type: "CP-CA",
    },
    content: [
      {
        id: `ah:manuscript:id:${aaManuscriptId({
          documentId,
          emAcronym: feederJournal.emAcronym,
          revision,
        })}`,
        type: `ah:manuscript`,
        format: `MIME-HTML`,
        title: aaTruncate(manuscriptTitle),
      },
    ],
    journal: {
      name: aaTruncate(journalData.title),
      issn: journalData.issn,
    },
  };

  // console.log("NEW PAGE", payload);

  readyStateDeferrer({
    sendAnalytics: () => window.pageDataTracker?.trackEvent("newPage", payload),
  });
}

export const trackSuggestionsView = () => {};

/**
 Elsevier docs: https://elsevier.atlassian.net/wiki/spaces/AA/pages/57123200272/How+to+Tag+a+Static+Error+Page
*/
export const trackErrorView = ({
  documentId,
  feederJournal,
  errorType,
  errorDetails,
  offerType,
  revision,
  title: manuscriptTitle,
}: TAppError & Partial<TTrackingData>) => {
  const aaManuscriptIdOrUnknown =
    documentId && feederJournal && feederJournal.emAcronym
      ? aaManuscriptId({
          documentId,
          emAcronym: feederJournal.emAcronym,
          revision,
        })
      : "unknown";

  const payload = {
    page: {
      name: `${aaOfferType(offerType)}:error-page`,
      type: "ER-GE",
    },
    content: [
      {
        id: `ah:manuscript:id:${aaManuscriptIdOrUnknown}`, // Try to get it if we have it
        type: `ah:manuscript`,
        format: `MIME-HTML`,
        title: aaTruncate(manuscriptTitle) || "unknown", // Try to get it if we have it
      },
    ],
    form: {
      errorType: aaErrorCodes({ errorType, errorDetails }),
    },
  };

  // console.log("ERROR PAGE", payload);

  readyStateDeferrer({
    sendAnalytics: () => window.pageDataTracker?.trackEvent("newPage", payload),
  });
};

/**
 * This event is to be invoked manually for any API error that does not redirect to the error page
 */
export const trackApiError = ({
  action,
  status = "unknown",
}: {
  action: string;
  status?: number | string | Record<string, unknown>;
}) => {
  const payload = {
    form: {
      errorType: `se-ah_${action}-${status}`,
    },
  };

  // console.log("FORM ERROR", payload);

  readyStateDeferrer({
    sendAnalytics: () =>
      window.pageDataTracker?.trackEvent("formError", payload),
  });
};

export const trackMenuButtonClick = (buttonName: string) => {
  const payload = { buttonType: `menu:${buttonName}` };

  // console.log("MENU BUTTON CLICK", payload);

  window.pageDataTracker?.trackEvent("buttonClick", payload);
};

export const trackFormButtonClick = (buttonName: string) => {
  const payload = { buttonType: `form control:${buttonName}` };

  // console.log("FORM BUTTON CLICK", payload);

  window.pageDataTracker?.trackEvent("buttonClick", payload);
};

export const appendTrackingIds = (href: string) => {
  let appendedUrl = href;

  if (window?.s?.visitor) {
    appendedUrl = window.s.visitor.appendVisitorIDsTo(href);
  }

  return appendedUrl;
};
