import React from "react";

import {
  isTransferByScientificManagingEditor,
  isTransferWithEditorSuggestions,
  TMergeElementProps,
  TOfferType,
} from "../lib";

import { Badge } from "../generic";
import * as css from "./OfferTypeIndicator.css";

export const byElsevierText = "Suggested by Elsevier";
export const byJournalEditorText = "Suggested by a journal editor";
export const bySMEText = "Scientific Managing Editor";

const OfferTypeIndicator = ({
  forwardCSS,
  offerType,
  ...rest
}: TMergeElementProps<
  "p",
  {
    offerType: TOfferType;
    testId?: undefined; // Redundant
  }
>) => {
  const defaultBadge = (
    <Badge testId="suggested-by" forwardCSS={css._offerTypeDefaultBadgeCSS}>
      {isTransferWithEditorSuggestions(offerType)
        ? byJournalEditorText
        : byElsevierText}
    </Badge>
  );

  const smeBadge = (
    <Badge testId="suggested-by-sme" forwardCSS={css._offerTypeSMEBadgeCSS}>
      {bySMEText}
    </Badge>
  );

  return (
    <p css={[css._offerTypeContainerCSS, forwardCSS]} {...rest}>
      {defaultBadge}
      {isTransferByScientificManagingEditor(offerType) && smeBadge}
    </p>
  );
};

export default OfferTypeIndicator;
