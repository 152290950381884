import { css } from "@emotion/react";
import { _minMediaCSS } from "../lib";

export const _evaluateGridCSS = css`
  display: grid;
  grid-gap: var(--column-gap);

  ${_minMediaCSS("m")} {
    grid-template-columns: repeat(6, 1fr);
  }
  ${_minMediaCSS("l")} {
    grid-template-columns: repeat(8, 1fr);
  }
  ${_minMediaCSS("xl")} {
    grid-template-columns: repeat(10, 1fr);
  }
`;

export const _resultColumnsCSS = css`
  .main-column {
    --box-margin: 0;
    padding-right: 0;
    ${_minMediaCSS("m")} {
      grid-column: 1 / span 3;
    }
    ${_minMediaCSS("l")} {
      grid-column: 1 / span 5;
    }
    ${_minMediaCSS("xl")} {
      grid-column: 1 / span 6;
    }
  }
  .side-column {
    --box-margin: 0;
    align-self: flex-end;

    ${_minMediaCSS("m")} {
      grid-column: span 3;
    }
    ${_minMediaCSS("xl")} {
      grid-column: span 4;
    }
  }
`

export const _downloadColumnsCSS = css`
  .main-column {
    ${_minMediaCSS("m")} {
      grid-column: 1 / span 4;
    }
    ${_minMediaCSS("l")} {
      grid-column: 1 / span 6;
    }
    ${_minMediaCSS("xl")} {
      grid-column: 1 / span 8;
    }
  }
  .side-column {
    ${_minMediaCSS("m")} {
      grid-column: span 2;
      justify-self: flex-end;
    }
  }
`

export const _evaluateH1Size = css`
  font-size: var(--font-size-xxl);
`

export const _evaluateH2Size = css`
  font-size: var(--font-size-l);
`

export const _evaluateH3Size = css`
  font-size: var(--font-size-m);
`
