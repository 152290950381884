import React, { forwardRef } from "react";

import {
  EvaluationStatus,
  handleApiError,
  TEvaluationStatus,
  trackApiError,
  trackFormButtonClick,
  TSubmissionData,
} from "../lib";
import { useConfirmEvaluation } from "../lib";
import { Box, BetaBadge, Button, UtilBar } from "../generic";
import ViewContainer from "../ViewContainer";
import BackToTransferStatusLink from "./BackToTransferStatusLink";
import { _evaluateH1Size } from "./EvalutateView.css";

const EvaluateConsent = forwardRef(
  (
    {
      dispatchEvaluationStatusUpdate,
      submissionData,
    }: {
      dispatchEvaluationStatusUpdate: React.Dispatch<TEvaluationStatus>;
      submissionData: TSubmissionData;
    },
    ref: React.Ref<HTMLHeadingElement>
  ) => {
    const { submissionUuid, transfer } = submissionData;

    const targetJournalTitle = transfer?.targetJournal
      ? transfer.targetJournal.title
      : "the journal";

    const confirmEvaluation = useConfirmEvaluation({
      submissionUuid,
      onMutate: () => {
        trackFormButtonClick("confirmAnalyseLanguage");
        dispatchEvaluationStatusUpdate(
          EvaluationStatus.AWAITING_INITIATED_FROM_API
        );
      },
      onError: (error: unknown) => {
        dispatchEvaluationStatusUpdate(EvaluationStatus.FAILED);
        const handledApiError = handleApiError(error);
        trackApiError({
          action: "init_evaluate_manuscript",
          status: handledApiError.errorDetails || "unknown",
        });
      },
    });

    return (
      <ViewContainer
        viewName="evaluate:consent"
        htmlTitle={`Improve your manuscript for ${submissionData.title}`}
        topTitle="Improve your manuscript for"
        submissionData={submissionData}
      >
        <Box>
          <BetaBadge id="is-beta" />
          <h1
            aria-describedby="is-beta"
            css={_evaluateH1Size}
            data-testid="evaluate-title"
            ref={ref}
            tabIndex={-1}
          >
            <strong>
              Improve your manuscript quickly and confidentially for free
            </strong>
          </h1>
          <p className="intro-text">
            Using generative AI, in minutes we can evaluate your manuscript,
            provide a language quality score and improve your likelihood of
            acceptance &mdash; all confidentially.
          </p>
          <p className="intro-text">
            You can then edit your manuscript before you complete your
            submission to {targetJournalTitle}, if you wish.
          </p>
        </Box>
        <Box>
          <UtilBar>
            <Button
              testId="confirm-analyse-button"
              onClick={() => confirmEvaluation.mutate()}
            >
              Analyse my manuscript
            </Button>
            <BackToTransferStatusLink />
          </UtilBar>
        </Box>
      </ViewContainer>
    );
  }
);

export default EvaluateConsent;
