import React from "react";
import { Navigate, useOutletContext } from "react-router-dom";

import { isOfferStatusActive, TViewContextProps } from "../lib";

import StatusView from "./StatusView";

const StatusViewRouter = () => {
  const { dispatchOfferStatusUpdate, submissionData } =
    useOutletContext<TViewContextProps>();

  if (isOfferStatusActive(submissionData.status)) {
    return (
      <Navigate
        to={`/transfers/${submissionData.submissionUuid}`}
        replace={true}
      />
    );
  }

  return (
    <StatusView
      dispatchOfferStatusUpdate={dispatchOfferStatusUpdate}
      submissionData={submissionData}
    />
  );
};

export default StatusViewRouter;
