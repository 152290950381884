import {
  useQueryClient,
  useMutation,
  UseMutationResult,
} from "@tanstack/react-query";
import { apiClient } from "../apiClient";
import { AxiosResponse } from "axios";
import { TEvaluationData } from "../types";

export const useConfirmEvaluation = ({
  submissionUuid,
  onMutate,
  onError,
}: {
  submissionUuid: string;
  onMutate: () => void;
  onError: (error: unknown) => void;
}): UseMutationResult<
  AxiosResponse<TEvaluationData, unknown>,
  unknown,
  void,
  void
> => {
  const queryClient = useQueryClient();

  const result = useMutation({
    mutationKey: [submissionUuid, "confirmEvaluation"],
    mutationFn: () => {
      return apiClient({
        method: "post",
        url: `/submissions/${submissionUuid}/language-editing`,
      });
    },
    retry: 3,
    onMutate: async () => {
      onMutate();
      // Don't fetch while data is mutating
      queryClient.cancelQueries({
        queryKey: ["language-editing", submissionUuid],
      });
    },
    onSettled: () => {
      // Mark data query as stale but don't refetch per se
      // Is react-query still refetching too soon? Check the query client settings in the App component.
      queryClient.invalidateQueries({
        queryKey: ["language-editing", submissionUuid],
      });
    },
    onError: (error: unknown) => {
      onError(error);
      // Start over
      queryClient.cancelQueries({
        queryKey: ["language-editing", submissionUuid],
      });
    },
  });

  return result;
};
