import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { apiClient, TExperimentGroup } from "..";
import { ExperimentGroup, isOfferStatusAwaitingTransferInitiatedFromApi, isOfferStatusTransferFailed, isOfferStatusTransferInitiated, isOfferStatusTransferSucceeded, TEvaluationData, TOfferStatus } from "../types";

// Returns status and score
export const useEvaluationData = ({
  languageExperiment,
  offerStatus,
  submissionUuid,
  shouldPoll,
}: {
  languageExperiment: TExperimentGroup;
  offerStatus: TOfferStatus;
  submissionUuid: string;
  shouldPoll?: boolean;
}): UseQueryResult<AxiosResponse<TEvaluationData, unknown>, Error> => {
  const hasCorrectOfferStatus =
    isOfferStatusAwaitingTransferInitiatedFromApi(offerStatus) ||
    isOfferStatusTransferInitiated(offerStatus) ||
    isOfferStatusTransferSucceeded(offerStatus) ||
    isOfferStatusTransferFailed(offerStatus)

  const isInExperimentGroup = languageExperiment === ExperimentGroup.EXPERIMENT

  const enabled = (
    !!submissionUuid &&
    !!isInExperimentGroup &&
    !!hasCorrectOfferStatus
  )

  return useQuery({
    queryKey: ["language-editing", submissionUuid],
    queryFn: async () => {
      const response = await apiClient({
        url: `/submissions/${submissionUuid}/language-editing`,
      });

      return response;
    },
    refetchInterval() {
      if (shouldPoll) {
        return 3000;
      }
      return false;
    },
    enabled,
  });
};
