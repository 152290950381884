import React from "react";

import { BetaBadge, Box, Icon, InternalLink } from "../generic";

import * as css from "./LanguageBanner.css";
import { _evaluateH2Size } from "../EvaluateView/EvalutateView.css";

const LanguageBannerAI = ({
  position,
  submissionUuid,
}: {
  position: "before-main-heading" | "after-main-heading";
  submissionUuid: string;
}) => {
  const Title = position === "after-main-heading" ? "h2" : "p";

  const titleProps = {
    "aria-describedby": "language-banner-beta",
    css: _evaluateH2Size,
    "data-testid": "language-banner-title",
  };

  return (
    <section
      css={css._languageBannerAIContainer}
      data-testid="language-banner-experiment"
    >
      <Box forwardCSS={css._languageBannerBetaBox}>
        <BetaBadge id="language-banner-beta" />
      </Box>
      <Box
        color="promo"
        variant="background"
        forwardCSS={css._languageBannerPromoBox}
      >
        <div css={css._languageBannerAIGridCSS}>
          <div className="language-banner-icon">
            <Icon name="check-clipboard" />
          </div>
          <div className="language-banner-content">
            <Title {...titleProps}>
              <strong>
                Is your manuscript written in high quality English?
              </strong>
            </Title>
            <p className="intro-text">
              In minutes, we can evaluate and suggest edits to the language and
              grammar that you have used.
            </p>
          </div>
          <div className="language-banner-link">
            <InternalLink
              analyticsRegion="ah-status"
              analyticsName="ah-goto-language-editing"
              icon={{ name: "navigate-right", position: "after" }}
              href={`/transfers/${submissionUuid}/evaluate`}
              styleType="primary"
              testId="language-editing-link"
            >
              Get your free evaluation
            </InternalLink>
          </div>
        </div>
      </Box>
    </section>
  );
};

export default LanguageBannerAI;
