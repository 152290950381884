import React from "react";

import callForPapersPicto from "../../../static/images/call-for-papers.picto.svg";
import journalFinderPicto from "../../../static/images/journal-finder.picto.svg";
import { callForPapersUrl, journalFinderUrl } from "../lib";
import { ExternalLink, Box, UtilBar, BetaBadge } from "../generic";
import * as css from "./MoreTransferOptions.css";

const MoreTransferOptions = () => {
  const journalFinderLinkProps = {
    analyticsRegion: "ah-status",
    analyticsName: `ah-goto-journal-finder`,
    href: journalFinderUrl,
    testId: `journal-finder-link`,
  };

  const callForPapersLinkProps = {
    analyticsRegion: "ah-status",
    analyticsName: `ah-goto-call-for-papers`,
    forwardCSS: css._moreTransferOptionsButtonCSS,
    href: callForPapersUrl,
    testId: `call-for-papers-link`,
  };

  return (
    <section>
      <Box>
        <h2 data-testid="more-transfer-options">
          <strong>We have more options to find a home for your work:</strong>
        </h2>
      </Box>
      <div css={css._moreTransferOptionsContainerCSS}>
        <Box
          element="section"
          forwardCSS={css._moreTransferOptionsContentCSS}
          variant="border"
        >
          <UtilBar>
            <img
              alt=""
              css={css._moreTransferOptionsPictoCSS}
              src={journalFinderPicto}
            />
            <h3 css={css._moreTransferOptionsHeadingCSS}>Journal Finder</h3>
          </UtilBar>
          <p>
            Match your manuscript using the Journal Finder and learn more about
            each journal that may be best suited for publishing your research.
          </p>
          <ExternalLink {...journalFinderLinkProps} styleType="secondary">
            Find a matching journal
          </ExternalLink>
        </Box>
        <Box
          element="section"
          forwardCSS={css._moreTransferOptionsContentCSS}
          variant="border"
        >
          <UtilBar forwardCSS={css._moreTransferOptionsTopCSS}>
            <img
              alt=""
              css={css._moreTransferOptionsPictoCSS}
              src={callForPapersPicto}
            />
            <h3 css={css._moreTransferOptionsHeadingCSS}>
              Call for papers{" "}
              <BetaBadge forwardCSS={css._moreTransferOptionsBetaCSS} />
            </h3>
          </UtilBar>
          <p>
            Find more opportunities to publish your research in a special issue:
          </p>
          <ExternalLink {...callForPapersLinkProps} styleType="secondary">
            Browse all calls for papers
          </ExternalLink>
        </Box>
      </div>
    </section>
  );
};

export default MoreTransferOptions;
