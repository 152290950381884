import { useLocation, useParams } from "react-router-dom";

export type TQueryParams = {
  acceptLink: string;
  journal: string;
  manuscriptId: string;
};

export type TUrlCurrentData = {
  issn: string | null;
  queryParams: TQueryParams | null;
  submissionUuid: string | null;
};

export type TUrlNewData = {
  issn: string | null;
  queryString: string | null;
  submissionUuid: string | null;
};

// Exported for testing
export const validateAcceptLink = (search: string) => {
  const q = new URLSearchParams(search);
  const journal = q.get("journal"); // Journal acronuym
  const manuscriptId = q.get("manuscriptId"); // Pub D number
  const acceptLink = search?.match("&acceptLink=(.*)$")?.[1];
  const dlid = acceptLink?.match("i=([0-9]*)")?.[1];
  const sid = acceptLink?.match("l=([A-Za-z0-9]*)")?.[1];

  const result =
    journal && manuscriptId && dlid && sid
      ? {
          acceptLink,
          journal,
          manuscriptId,
        }
      : null;

  return result;
};

// Exported for testing
export function reduceUrl(currentData: TUrlCurrentData, newData: TUrlNewData) {
  const { issn, queryString, submissionUuid } = newData;

  if (submissionUuid) {
    return { ...currentData, issn, submissionUuid };
  } else if (queryString) {
    const queryParams = validateAcceptLink(queryString);
    if (queryParams) {
      return { ...currentData, queryParams };
    } else {
      return { ...currentData };
    }
  } else {
    return { ...currentData };
  }
}

export const useUrl = () => {
  const { search } = useLocation();
  const { issn, submissionUuid } = useParams();

  const result = reduceUrl(
    {
      issn: null,
      queryParams: null,
      submissionUuid: null,
    },
    {
      issn: issn || null,
      queryString: search,
      submissionUuid: submissionUuid || null,
    }
  );

  return result;
};
