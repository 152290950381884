import { TSuggestedJournalData } from "..";

type TUseSuggestedJournal = ({
  issn,
  suggestedJournals,
}: {
  issn: string | null;
  suggestedJournals?: TSuggestedJournalData[];
}) => TSuggestedJournalData | null;

// Exported for testing
export const reduceSuggestedJournal = ({
  issn,
  suggestedJournals,
}: {
  issn: string | null;
  suggestedJournals?: TSuggestedJournalData[];
}) => {
  if (!issn || !suggestedJournals) {
    return null;
  }

  const suggestedJournal = suggestedJournals.find(
    (journal) => journal.issn === issn
  );

  return suggestedJournal || null;
};

export const useSuggestedJournal: TUseSuggestedJournal = ({
  issn,
  suggestedJournals,
}) => {
  const result = reduceSuggestedJournal({ issn, suggestedJournals });

  return result;
};
