import { Interpolation, Theme } from "@emotion/react";

export type TForwardCSS = Interpolation<Theme>;

export type TMergeElementProps<
  T extends React.ElementType,
  P extends object = Record<string, unknown>,
> = Omit<React.ComponentPropsWithRef<T>, keyof P> &
  P & {
    css?: undefined; // Not allowed on component. Ok on html element.
    "data-testid"?: undefined; // Not allowed on component. Ok on html element.
    forwardCSS?: TForwardCSS;
    testId?: string;
  };
