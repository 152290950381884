import { css } from "@emotion/react";

export const _hrBase = css`
  --hr-border: 1px solid var(--color-border);
  border-top: var(--hr-border);
  &:where(.hr-roomy) {
    margin-top: var(--spacing-l);
    margin-bottom: var(--spacing-l);
  }
  &:where(.hr-cozy) {
    margin-top: var(--spacing-s);
    margin-bottom: var(--spacing-s);
  }
  /* Selectors may not be working */
  &.hr-loop:nth-first-child(1),
  .box-border + &,
  .box-highlight + & {
    display: none;
  }
`;
