import {
  TExperimentGroup,
  TFeederJournalData,
  TOfferStatus,
  TOfferStatusFromApi,
  TOfferType,
  TSuggestedJournalData,
  TTransferData,
} from ".";

export interface TSubmissionData {
  declinedOn?: string;
  documentId: number;
  languageExperiment: TExperimentGroup;
  feederJournal: TFeederJournalData;
  offerType: TOfferType;
  pubDNumber: string;
  revision?: number;
  submissionUuid: string;
  suggestedJournals: TSuggestedJournalData[];
  status: TOfferStatus | TOfferStatusFromApi;
  transfer: TTransferData | null;
  title: string; // Manuscript title
}
