import { formatJournalTitleAsUrl } from "..";

export const getJournalHomePageUrl = (journalTitle: string) => {
  const domain = "https://www.sciencedirect.com/journal";
  const journal = formatJournalTitleAsUrl(journalTitle);
  return `${domain}/${journal}`;
};

export const getOpenAccessInfoUrl = (journalTitle: string) => {
  const homePageUrl = getJournalHomePageUrl(journalTitle);
  return `${homePageUrl}/publish/open-access-options`;
};

export const aboutServicesLink =
  "https://service.elsevier.com/app/answers/detail/a_id/34314/supporthub/publishing/role/author";

export const callForPapersUrl =
  "https://www.sciencedirect.com/browse/calls-for-papers";

export const journalFinderUrl = "https://journalfinder.elsevier.com/";

export const languageEditingService =
  "https://webshop.elsevier.com/language-editing";

