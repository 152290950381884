/*
  The evils of enums in Typescript: https://dev.to/ivanzm123/dont-use-enums-in-typescript-they-are-very-dangerous-57bh
  Best solution: https://www.typescriptlang.org/docs/handbook/enums.html#objects-vs-enums
*/

export const OfferStatus = {
  LEGACY_AWAITING_AUTHENTICATION: "AWAITING_AUTHENTICATION",
  OFFER_ACTIVE: "ACTIVE",
  AWAITING_CONFIRM_TRANSFER_FROM_USER: "AWAITING_CONFIRM_TRANSFER_FROM_USER", // Front end only, not in the (mock) database
  AWAITING_TRANSFER_INITIATED_FROM_API: "AWAITING_TRANSFER_INITIATED_FROM_API", // Front end only, not in the (mock) database
  TRANSFER_INITIATED: "INITIATED",
  TRANSFER_SUCCEEDED: "SUCCEEDED",
  TRANSFER_FAILED: "FAILED",
  AWAITING_CONFIRM_DECLINE_FROM_USER: "AWAITING_CONFIRM_DECLINE_FROM_USER", // Front end only, not in the (mock) database
  AWAITING_DECLINED_INITIATED_FROM_API: "AWAITING_DECLINED_INITIATED_FROM_API", // Front end only, not in the (mock) database
  OFFER_DECLINED: "DECLINED", // In our system or in Editorial Manager
  OFFER_EXPIRED: "EXPIRED", // In Editorial Manager
  OFFER_ACCEPTED: "ACCEPTED", // = Transfer happened in Editorial Manager
  OFFER_RESCINDED: "RESCINDED", // In Editorial Manager
} as const;
export type TOfferStatus = (typeof OfferStatus)[keyof typeof OfferStatus];

export const OrderedOfferStatus = {
  [OfferStatus.LEGACY_AWAITING_AUTHENTICATION]: 0,
  [OfferStatus.OFFER_ACTIVE]: 1,
  [OfferStatus.AWAITING_CONFIRM_TRANSFER_FROM_USER]: 2,
  [OfferStatus.AWAITING_TRANSFER_INITIATED_FROM_API]: 3,
  [OfferStatus.TRANSFER_INITIATED]: 4,
  [OfferStatus.OFFER_ACCEPTED]: 5,
  [OfferStatus.TRANSFER_SUCCEEDED]: 6,
  [OfferStatus.TRANSFER_FAILED]: 7,
  [OfferStatus.AWAITING_CONFIRM_DECLINE_FROM_USER]: 8,
  [OfferStatus.AWAITING_DECLINED_INITIATED_FROM_API]: 9,
  [OfferStatus.OFFER_DECLINED]: 10,
  [OfferStatus.OFFER_EXPIRED]: 11,
  [OfferStatus.OFFER_RESCINDED]: 12,
} as const;
export type TOrderedOfferStatus =
  (typeof OrderedOfferStatus)[keyof typeof OrderedOfferStatus];

export type TOfferStatusLegacyAwaitingAuthentication =
  typeof OfferStatus.LEGACY_AWAITING_AUTHENTICATION;
export type TOfferStatusActive = typeof OfferStatus.OFFER_ACTIVE;
export type TOfferStatusAwaitingConfirmTransferFromUser =
  typeof OfferStatus.AWAITING_CONFIRM_TRANSFER_FROM_USER;
export type TOfferStatusAwaitingTransferInitiatedFromApi =
  typeof OfferStatus.AWAITING_TRANSFER_INITIATED_FROM_API;
export type TOfferStatusTransferInitiated =
  typeof OfferStatus.TRANSFER_INITIATED;
export type TOfferStatusTransferSucceeded =
  typeof OfferStatus.TRANSFER_SUCCEEDED;
export type TOfferStatusTransferFailed = typeof OfferStatus.TRANSFER_FAILED;
export type TOfferStatusAwaitingConfirmDeclineFromUser =
  typeof OfferStatus.AWAITING_CONFIRM_DECLINE_FROM_USER;
export type TOfferStatusAwaitingDeclinedInitiatedFromApi =
  typeof OfferStatus.AWAITING_DECLINED_INITIATED_FROM_API;
export type TOfferStatusDeclined = typeof OfferStatus.OFFER_DECLINED;
export type TOfferStatusExpired = typeof OfferStatus.OFFER_EXPIRED;
export type TOfferStatusAccepted = typeof OfferStatus.OFFER_ACCEPTED;
export type TOfferStatusRescinded = typeof OfferStatus.OFFER_RESCINDED;

export type TOfferStatusDEAR =
  | TOfferStatusDeclined
  | TOfferStatusExpired
  | TOfferStatusAccepted
  | TOfferStatusRescinded;

export type TOfferStatusHasTransferData =
  | TOfferStatusLegacyAwaitingAuthentication
  | TOfferStatusAwaitingConfirmTransferFromUser
  | TOfferStatusAwaitingTransferInitiatedFromApi
  | TOfferStatusTransferInitiated
  | TOfferStatusTransferSucceeded
  | TOfferStatusTransferFailed;

export type TOfferStatusFromApi =
  | TOfferStatusLegacyAwaitingAuthentication
  | TOfferStatusActive
  | TOfferStatusTransferInitiated
  | TOfferStatusTransferSucceeded
  | TOfferStatusTransferFailed
  | TOfferStatusDEAR;

export type TOfferStatusFromUser =
  | TOfferStatusAwaitingConfirmTransferFromUser
  | TOfferStatusAwaitingTransferInitiatedFromApi
  | TOfferStatusAwaitingConfirmDeclineFromUser
  | TOfferStatusAwaitingDeclinedInitiatedFromApi;

// Type guards

export function isOfferStatusActive(t: string): t is TOfferStatusActive {
  return t === OfferStatus.OFFER_ACTIVE;
}

export function isOfferStatusAwaitingConfirmTransferFromUser(
  t: string
): t is TOfferStatusAwaitingConfirmTransferFromUser {
  return t === OfferStatus.AWAITING_CONFIRM_TRANSFER_FROM_USER;
}

export function isOfferStatusAwaitingTransferInitiatedFromApi(
  t: string
): t is TOfferStatusAwaitingConfirmTransferFromUser {
  return t === OfferStatus.AWAITING_TRANSFER_INITIATED_FROM_API;
}

export function isOfferStatusTransferInitiated(
  t: string
): t is TOfferStatusTransferInitiated {
  return t === OfferStatus.TRANSFER_INITIATED;
}

export function isOfferStatusTransferSucceeded(
  t: string
): t is TOfferStatusTransferSucceeded {
  return t === OfferStatus.TRANSFER_SUCCEEDED;
}

export function isOfferStatusTransferFailed(
  t: string
): t is TOfferStatusTransferFailed {
  return t === OfferStatus.TRANSFER_FAILED;
}

export function isOfferStatusAwaitingConfirmDeclineFromUser(
  t: string
): t is TOfferStatusAwaitingConfirmDeclineFromUser {
  return t === OfferStatus.AWAITING_CONFIRM_DECLINE_FROM_USER;
}

export function isOfferStatusAwaitingDeclinedInitiatedFromApi(
  t: string
): t is TOfferStatusAwaitingDeclinedInitiatedFromApi {
  return t === OfferStatus.AWAITING_DECLINED_INITIATED_FROM_API;
}

export function isOfferStatusDeclined(t: string): t is TOfferStatusDeclined {
  return t === OfferStatus.OFFER_DECLINED;
}

export function isOfferStatusAccepted(t: string): t is TOfferStatusAccepted {
  return t === OfferStatus.OFFER_ACCEPTED;
}

export function isOfferStatusExpired(t: string): t is TOfferStatusExpired {
  return t === OfferStatus.OFFER_EXPIRED;
}

export function isOfferStatusRescinded(t: string): t is TOfferStatusRescinded {
  return t === OfferStatus.OFFER_RESCINDED;
}

export function isOfferStatusDEAR(t: string): t is TOfferStatusDEAR {
  return (
    isOfferStatusDeclined(t) ||
    isOfferStatusExpired(t) ||
    isOfferStatusAccepted(t) ||
    isOfferStatusRescinded(t)
  );
}

export function isOfferStatusLegacyAwaitingAuthentication(
  t: string
): t is TOfferStatusLegacyAwaitingAuthentication {
  return t === OfferStatus.LEGACY_AWAITING_AUTHENTICATION;
}

export function isOfferStatusFromApi(t: string): t is TOfferStatusFromApi {
  return (
    isOfferStatusLegacyAwaitingAuthentication(t) ||
    isOfferStatusActive(t) ||
    isOfferStatusTransferInitiated(t) ||
    isOfferStatusTransferSucceeded(t) ||
    isOfferStatusTransferFailed(t) ||
    isOfferStatusDEAR(t)
  );
}

export function isOfferStatusHasTransferData(
  t: string
): t is TOfferStatusHasTransferData {
  return (
    isOfferStatusAwaitingConfirmTransferFromUser(t) ||
    isOfferStatusAwaitingTransferInitiatedFromApi(t) ||
    isOfferStatusTransferInitiated(t) ||
    isOfferStatusTransferSucceeded(t) ||
    isOfferStatusTransferFailed(t) ||
    isOfferStatusLegacyAwaitingAuthentication(t)
  );
}
