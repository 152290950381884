import React, { forwardRef } from "react";
import { Box } from "../generic";
import { TSubmissionData, TTransferData } from "../lib";
import {
  statusMessageTitleProps,
  statusMessageContentProps,
  AssistanceMessage,
} from "./StatusView.utils";
import ViewContainer from "../ViewContainer";
import LanguageBannerRouter from "../LanguageBanner/LanguageBannerRouter";

const TransferFailedMessage = forwardRef(
  (
    {
      submissionData,
    }: {
      submissionData: TSubmissionData & {
        transfer: TTransferData;
      };
    },
    ref: React.Ref<HTMLHeadingElement>
  ) => {
    const {
      languageExperiment: languageExperiment,
      submissionUuid,
      status: offerStatus,
      title: manuscriptTitle,
      transfer: { targetJournal },
    } = submissionData;

    return (
      <ViewContainer
        viewName="status"
        htmlTitle={`Transfer failed for ${manuscriptTitle}`}
        topTitle="Status update for"
        submissionData={submissionData}
      >
        <Box color="alert" iconName="failed-solid-circle" variant="background">
          <h1 {...statusMessageTitleProps} ref={ref}>
            Transfer failed
          </h1>
          <p {...statusMessageContentProps}>
            We&apos;re sorry, transfer of your manuscript to{" "}
            <em>{targetJournal.title}</em> failed. This is a problem with our
            system.
          </p>
          <AssistanceMessage />
        </Box>
        <LanguageBannerRouter
          languageExperiment={languageExperiment}
          offerStatus={offerStatus}
          position="after-main-heading"
          submissionUuid={submissionUuid}
        />
      </ViewContainer>
    );
  }
);

export default TransferFailedMessage;
