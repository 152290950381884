import React from "react";

import * as lib from "../lib";

import ErrorView from "../ErrorView";
import ConfirmTransferForm from "./ConfirmTransferForm";
import TransferInitiatedMessage from "./TransferInitiatedMessage";
import TransferSucceededMessage from "./TransferSucceededMessage";
import ConfirmDeclineForm from "./ConfirmDeclineForm";
import TransferFailedMessage from "./TransferFailedMessage";
import LegacyAwaitingAuthenticationForm from "./LegacyAwaitingAuthenticationForm";
import OfferDeclinedMessage from "./OfferDeclinedMessage";
import OfferExpiredMessage from "./OfferExpiredMessage";
import OfferRescindedMessage from "./OfferRescindedMessage";

export const getStatusViewTitle = ({
  text,
  manuscriptTitle,
}: {
  text: string;
  manuscriptTitle: string;
}) => {
  return `${text} for ${manuscriptTitle}`;
};

const StatusView = ({
  dispatchOfferStatusUpdate,
  submissionData,
}: lib.TViewContextProps) => {
  const { declinedOn, status, transfer } = submissionData;

  const focusRef = lib.useFocusRef<HTMLHeadingElement>({
    documentCookie: document.cookie,
    dependencies: [status],
    shouldFocus: true,
  });

  if (lib.isOfferStatusLegacyAwaitingAuthentication(status) && transfer) {
    return (
      <LegacyAwaitingAuthenticationForm
        ref={focusRef}
        dispatchOfferStatusUpdate={dispatchOfferStatusUpdate}
        submissionData={{ ...submissionData, transfer }}
      />
    );
  } else if (
    lib.isOfferStatusAwaitingConfirmTransferFromUser(status) &&
    transfer
  ) {
    return (
      <ConfirmTransferForm
        ref={focusRef}
        dispatchOfferStatusUpdate={dispatchOfferStatusUpdate}
        submissionData={{ ...submissionData, transfer }}
      />
    );
  } else if (
    (lib.isOfferStatusAwaitingTransferInitiatedFromApi(status) && transfer) ||
    (lib.isOfferStatusTransferInitiated(status) && transfer)
  ) {
    return (
      <TransferInitiatedMessage
        ref={focusRef}
        submissionData={{ ...submissionData, transfer }}
      />
    );
  } else if (
    (lib.isOfferStatusTransferSucceeded(status) && transfer) ||
    lib.isOfferStatusAccepted(status)
  ) {
    return (
      <TransferSucceededMessage
        ref={focusRef}
        submissionData={submissionData}
      />
    );
  } else if (lib.isOfferStatusTransferFailed(status) && transfer) {
    return (
      <TransferFailedMessage
        ref={focusRef}
        submissionData={{ ...submissionData, transfer }}
      />
    );
  } else if (
    lib.isOfferStatusAwaitingConfirmDeclineFromUser(status) &&
    declinedOn
  ) {
    return (
      <ConfirmDeclineForm
        ref={focusRef}
        dispatchOfferStatusUpdate={dispatchOfferStatusUpdate}
        submissionData={{ ...submissionData, declinedOn }}
      />
    );
  } else if (
    (lib.isOfferStatusAwaitingDeclinedInitiatedFromApi(status) && declinedOn) ||
    lib.isOfferStatusDeclined(status)
  ) {
    return (
      <OfferDeclinedMessage
        ref={focusRef}
        submissionData={{ ...submissionData, declinedOn }}
      />
    );
  } else if (lib.isOfferStatusExpired(status)) {
    return (
      <OfferExpiredMessage submissionData={submissionData} ref={focusRef} />
    );
  } else if (lib.isOfferStatusRescinded(status)) {
    return (
      <OfferRescindedMessage ref={focusRef} submissionData={submissionData} />
    );
  }

  return (
    <ErrorView
      errorType={lib.AppErrorType.JAVASCRIPT_ERROR}
      submissionData={submissionData}
    />
  );
};

export default StatusView;
