import { css } from "@emotion/react";
import { _maxMediaCSS, _srOnlyCSS } from ".";

// Text for screen readers only
// Consider whether visual users would also benefit from having access to the element before using

export const _srOnlyXSMediaCSS = css`
  ${_maxMediaCSS("xs")} {
    ${_srOnlyCSS}
  }
`;

export const _srOnlySMediaCSS = css`
  ${_maxMediaCSS("s")} {
    ${_srOnlyCSS}
  }
`;

export const _srOnlyMMediaCSS = css`
  ${_maxMediaCSS("m")} {
    ${_srOnlyCSS}
  }
`;

export const _srOnlyLMediaCSS = css`
  ${_maxMediaCSS("l")} {
    ${_srOnlyCSS}
  }
`;

// Display none
// Makes the element inaccessible so be sure that you want it gone!

export const _hideXSMediaCSS = css`
  ${_maxMediaCSS("xs")} {
    display: none;
  }
`;

export const _hideSMediaCSS = css`
  ${_maxMediaCSS("s")} {
    display: none;
  }
`;

export const _hideMMediaCSS = css`
  ${_maxMediaCSS("m")} {
    display: none;
  }
`;

export const _hideLMediaCSS = css`
  ${_maxMediaCSS("l")} {
    display: none;
  }
`;
