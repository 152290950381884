import React, { forwardRef } from "react";

import { TSubmissionData } from "../lib";
import { BetaBadge, Box } from "../generic";

import ViewContainer from "../ViewContainer";

import BackToTransferStatusLink from "./BackToTransferStatusLink";
import { _evaluateH1Size } from "./EvalutateView.css";

const EvaluateFailed = forwardRef(
  (
    {
      submissionData,
    }: {
      submissionData: TSubmissionData;
    },
    ref: React.Ref<HTMLHeadingElement>
  ) => {
    return (
      <ViewContainer
        viewName="evaluate:failed"
        htmlTitle={`Manuscript analysis failed for ${submissionData.title}`}
        topTitle="Improve your manuscript for"
        submissionData={submissionData}
      >
        <Box>
          <BetaBadge id="is-beta" />
          <h1
            aria-describedby="is-beta"
            css={_evaluateH1Size}
            data-testid="evaluate-title"
            ref={ref}
            tabIndex={-1}
          >
            Analysis failed
          </h1>
          <p>
            We are sorry but we were unable to analyse your manuscript. This is
            a problem with our system.
          </p>
          <BackToTransferStatusLink />
        </Box>
      </ViewContainer>
    );
  }
);

export default EvaluateFailed;
