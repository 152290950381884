import React from "react";

import { Icon } from "../Icon";
import { TButtonLinkInside } from "./BL.types";
import { buttonLinkIconCSS } from "./BL.css";

export const ButtonLinkInside = ({
  children,
  icon: propsIcon,
}: TButtonLinkInside) => {
  const iconName = propsIcon && propsIcon.name;
  const iconPosition = propsIcon && (propsIcon.position || "after");

  const iconComponent = iconName && iconPosition && (
    <Icon
      forwardCSS={buttonLinkIconCSS}
      className={`button-link-icon-${iconPosition}`}
      name={iconName}
    />
  );

  return (
    <span className="button-link-inside">
      {iconName && iconPosition === "before" && iconComponent}
      <span className="button-link-content">{children}</span>
      {iconName && iconPosition === "after" && iconComponent}
    </span>
  );
};
