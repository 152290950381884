export type TBreakpoints = "xs" | "s" | "m" | "l" | "xl";

export const breakpoints: {
  [Property in TBreakpoints]: number;
} = {
  xs: 375,
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
};

export const _minMediaCSS = (breakpoint: TBreakpoints) => {
  return `@media screen and (min-width: ${breakpoints[breakpoint]}px)`;
};

export const _maxMediaCSS = (breakpoint: TBreakpoints) => {
  return `@media screen and (max-width: ${breakpoints[breakpoint] - 1}px)`;
};
