import { css } from "@emotion/react";

export const _betaBadgeCSS = css`
  display: block;
  width: fit-content;
  padding: 0 var(--spacing-xs);
  border: 1px solid currentColor;
  border-radius: 1rem;
  font-size: var(--font-size-xs);
  color: inherit;
`;
