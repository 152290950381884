import { css } from "@emotion/react";

export const toggleMenuDialogCSS = (isOpen?: boolean) => css`
  /* Reset some things */
  background: magenta;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  max-width: none;
  height: 100vh;
  max-height: none;
  margin: 0;
  padding: 0;
  border: 0;
  background: rgba(var(--es-color-grey2-rgb), 85%);
  /* Style the content */
  .toggle-menu-content {
    position: static;
    width: 320px;
    max-width: calc(100vw - 1rem);
    height: 100vh;
    margin-left: auto;
    padding: 1em;
    background: white;
  }
  .toggle-menu-close-form {
    text-align: right;
  }
  /* Open and close the dialog */
  display: block;
  visibility: hidden;
  opacity: ${isOpen ? 1 : 0};
  &[open] {
    visibility: visible;
    opacity: 1;
  }
  transition: opacity 0.3s;
  .toggle-menu-content {
    transform: translate(${isOpen ? "0" : "100%"}, 0);
    transition: transform 0.2s;
  }
`;

export const toggleMenuButtonCSS = css`
  .button-link-content {
    min-width: 2rem;
    min-height: calc(
      1rem + 9px
    ); /* Not liking the 9px here but it looks good on the menu button. */
    line-height: 0;
  }
`;
