import React from "react";

import { TCASInformation, TMergeElementProps } from "../lib";

import * as css from "./JournalMetrics.css";

type TJournalCASMetrics = TMergeElementProps<
  "p",
  {
    casInformation: TCASInformation;
  }
>;

const JournalCASMetrics = ({
  casInformation,
  forwardCSS,
}: TJournalCASMetrics) => {
  return (
    <p css={[css._metricsCASInfoCSS, forwardCSS]}>
      <span css={css._metricsTitleCSS}>CAS Journal Ranking</span>
      <span css={css._metricsValueCSS}>
        {casInformation.majorRanking.name} Tier{" "}
        {casInformation.majorRanking.tier}
      </span>
    </p>
  );
};

export default JournalCASMetrics;
