import { css } from "@emotion/react";
import { _utilBarContainerCSS } from "../generic/UtilBar/UtilBar.css";

export const detailNavigationCSS = css`
  ${_utilBarContainerCSS}
  width: 100%;
  max-width: 100%;
`;

export const detailViewBackLinkCSS = css`
  margin-right: auto;
`;
