import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import useIsOpen from "./useIsOpen";

type TooltipPosition = {
  x: "left" | "right";
  y: "top" | "bottom";
};

// Calculate the position of the tooltip based on the space available
const calculatePosition: (p: {
  target: HTMLButtonElement;
  popup: HTMLDivElement;
}) => TooltipPosition = ({ target: label, popup }) => {
  const labelBox = label.getBoundingClientRect();
  const contentBox = popup.getBoundingClientRect();
  const margin = { x: 50, y: 50 };
  const windowWidth = window.innerWidth;

  const x =
    labelBox.left + contentBox.width < windowWidth - margin.x
      ? "right"
      : "left";
  const y = labelBox.top - contentBox.height > margin.y ? "top" : "bottom";

  return { x, y };
};

const useTooltipPositionManager = ({
  tooltipRef,
}: {
  tooltipRef: RefObject<HTMLDivElement>;
}) => {
  const [position, setPosition] = useState<TooltipPosition>({
    x: "right",
    y: "top",
  });
  // TODO: maybe move target to the icon and adjust the position now?
  const targetRef = useRef<HTMLButtonElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);

  const { isOpen } = useIsOpen({ tooltipRef });

  const updatePosition = useCallback(() => {
    if (!popupRef.current || !targetRef.current) {
      return;
    }
    const newPosition = calculatePosition({
      target: targetRef.current,
      popup: popupRef.current,
    });
    if (newPosition.x === position.x && newPosition.y === position.y) {
      return;
    }
    setPosition(newPosition);
  }, [popupRef, targetRef, position, setPosition]);

  useEffect(() => {
    updatePosition();
  }, []);

  useEffect(() => {
    const handleUpdatePosition = updatePosition;

    if (isOpen) {
      window.addEventListener("resize", handleUpdatePosition);
      window.addEventListener("scroll", handleUpdatePosition);
    }

    return () => {
      window.removeEventListener("resize", handleUpdatePosition);
      window.removeEventListener("scroll", handleUpdatePosition);
    };
  }, [isOpen, updatePosition]);

  return { targetRef, popupRef, position };
};

export default useTooltipPositionManager;
