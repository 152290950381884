import { css } from "@emotion/react";

export type TBoxColor = "none" | "alert" | "dim" | "info" | "promo" | "success" | "warning";

export type TBoxVariant = "none" | "background" | "border" | "highlight";

export const _boxCSS = css`
  --box-margin: 0;
  --box-padding: 0;
  margin: var(--box-margin);
  padding: var(--box-padding);
  &.box-with-icon {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-column-gap: var(--column-gap);
  }
  &.box-none {
    --box-margin: calc(var(--column-gap) * 1.5) 0;
    --box-padding: 0 var(--page-gap);
  }
  &.box-border {
    --box-margin: calc(var(--column-gap) * 1.5) 0;
    --box-padding: calc(var(--column-gap) * 1.5) var(--page-gap);
    box-shadow: inset 0 0 0 4px var(--color-dim-light);
  }
  &.box-highlight {
    --box-margin: calc(var(--column-gap) * 1.5) 0;
    .box-topper {
      padding: calc(var(--column-gap) * 0.75) var(--page-gap);
      background: var(--color-surface);
      color: var(--color-on-surface);
      border: 1px solid var(--color-on-surface);
      border-right: 0;
      border-left: 0;
    }
    .box-content {
      padding: calc(var(--column-gap) * 1.5) var(--page-gap);
    }
  }
  &.box-background {
    --color-surface: var(--color-dim-light);
    --color-on-surface: var(--color-dim);
    --box-margin: calc(var(--column-gap) * 1.5) 0;
    --box-padding: calc(var(--column-gap) * 1.5) var(--page-gap);
    background: var(--color-surface);
    color: var(--color-on-surface);
  }
  &.box-dim {
    --color-surface: var(--color-dim-light);
    --color-on-surface: var(--color-dim);
    .button-text,
    .button-text-underlined {
      --color-interaction: var(--color-dim);
      --color-interaction-hover: var(--color-dim);
      --color-on-interaction: var(--color-dim-light);      
    }
  }
  &.box-info {
    --color-surface: var(--color-info-light);
    --color-on-surface: var(--color-info);
    .button-text,
    .button-text-underlined {
      --color-interaction: var(--color-info);
      --color-interaction-hover: var(--color-info);
      --color-on-interaction: var(--color-info-light);      
    }
  }
  &.box-success {
    --color-surface: var(--color-success-light);
    --color-on-surface: var(--color-success);
    .button {
      --color-interaction: var(--color-success);
      --color-interaction-hover: var(--color-success);
      --color-on-interaction: var(--color-success-light);
    }
  }
  &.box-warning {
    --color-surface: var(--color-warning-light);
    --color-on-surface: var(--color-warning);
    .button {
      --color-interaction: var(--color-warning);
      --color-interaction-hover: var(--color-warning);
      --color-on-interaction: var(--color-warning-light);
    }
  }
  &.box-alert {
    --color-surface: var(--color-alert-light);
    --color-on-surface: var(--color-alert);
    .button {
      --color-interaction: var(--color-alert);
      --color-interaction-hover: var(--color-alert);
      --color-on-interaction: var(--color-alert-light);
    }
  }
  &.box-promo {
    --color-surface: var(--color-info);
    --color-on-surface: white;
    .button {
      --color-interaction: white;
      --color-interaction-hover: white;
      --color-on-interaction: var(--color-info);
    }
  }
`;

export const _boxIconCSS = css`
  width: 2rem;
  height: 2rem;
  color: var(--color-on-surface);
`;