import React, { forwardRef } from "react";
import { Box } from "../generic";
import { TSubmissionData } from "../lib";
import LoginToJournalLink from "./LoginToJournalLink";
import {
  statusMessageTitleProps,
  statusMessageContentProps,
} from "./StatusView.utils";
import SubmissionInstructions from "./SubmissionInstructions";
import ViewContainer from "../ViewContainer";
import LanguageBannerRouter from "../LanguageBanner/LanguageBannerRouter";

const TransferSucceededMessage = forwardRef(
  (
    {
      submissionData,
    }: {
      submissionData: TSubmissionData;
    },
    ref: React.Ref<HTMLHeadingElement>
  ) => {
    const {
      languageExperiment: languageExperiment,
      pubDNumber,
      submissionUuid,
      status: offerStatus,
      title: manuscriptTitle,
      transfer,
    } = submissionData;

    return (
      <ViewContainer
        viewName="status"
        htmlTitle={`Complete your submission for ${manuscriptTitle}`}
        topTitle="Complete your submission for"
        submissionData={submissionData}
      >
        <Box>
          <LanguageBannerRouter
            languageExperiment={languageExperiment}
            offerStatus={offerStatus}
            pubDNumber={pubDNumber}
            position="before-main-heading"
            submissionUuid={submissionUuid}
          />
          <h1 {...statusMessageTitleProps} ref={ref}>
            <strong>
              Complete your submission to{" "}
              {transfer?.targetJournal ? (
                <em>{transfer.targetJournal.title}</em>
              ) : (
                "the journal"
              )}
            </strong>
          </h1>
          {transfer?.targetJournal ? (
            <>
              <p {...statusMessageContentProps}>
                On {transfer?.initiatedDate} your files were sent to{" "}
                <em>{transfer.targetJournal.title}</em> where we started a new
                submission for you. Please note this submission was created, but
                not submitted. If you already have an account with{" "}
                <em>{transfer.targetJournal.title}</em>, please sign in to find
                your new submission. If you do not already have an account, you
                will receive an email with instructions for signing in.
              </p>
              <LoginToJournalLink
                analyticsRegion="ah-status"
                targetJournal={transfer.targetJournal}
                variant="button"
              />
            </>
          ) : (
            <p {...statusMessageContentProps}>
              Your files have been sent to your chosen journal where we have
              started a new submission for you. Please note this submission has
              been created, but not submitted. If you already have an account
              with the journal, please sign in to find your new submission. If
              you do not already have an account, you will receive an email with
              instructions for signing in.{" "}
            </p>
          )}
        </Box>
        <SubmissionInstructions
          analyticsRegion="ah-status"
          targetJournal={transfer?.targetJournal}
        />
      </ViewContainer>
    );
  }
);

export default TransferSucceededMessage;
