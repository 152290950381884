import { useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";

import {
  OfferStatus,
  OrderedOfferStatus,
  TOfferStatus,
  TTransferData,
  isOfferStatusActive,
  isOfferStatusAwaitingConfirmTransferFromUser,
  isOfferStatusAwaitingConfirmDeclineFromUser,
} from "../types";

import { useUrl } from "../useUrl";

export const StatusUpdateTypes = {
  OPEN_CONFIRM_TRANSFER_FORM: "OPEN_CONFIRM_TRANSFER_FORM",
  INITIATE_TRANSFER: "INITIATE_TRANSFER",
  THROW_TRANSFER_ERROR: "THROW_TRANSFER_ERROR",
  OPEN_CONFIRM_DECLINE_FORM: "OPEN_CONFIRM_DECLINE_FORM",
  INITIATE_DECLINE: "INITIATE_DECLINE",
  CANCEL: "CANCEL",
  DONT_GO_BACK: "DONT_GO_BACK",
} as const;

export type TUseStatusUpdate = {
  declinedOn: string | null;
  status: TOfferStatus | null;
  transfer: TTransferData | null;
};

export type TUseStatusUpdateActions =
  | {
      action: typeof StatusUpdateTypes.CANCEL;
    }
  | {
      action: typeof StatusUpdateTypes.OPEN_CONFIRM_TRANSFER_FORM;
      transfer: TTransferData;
    }
  | {
      action: typeof StatusUpdateTypes.INITIATE_TRANSFER;
      transfer: TTransferData;
    }
  | {
      action: typeof StatusUpdateTypes.THROW_TRANSFER_ERROR;
      transfer: TTransferData;
    }
  | {
      action: typeof StatusUpdateTypes.OPEN_CONFIRM_DECLINE_FORM;
      declinedOn: string | null;
    }
  | {
      action: typeof StatusUpdateTypes.INITIATE_DECLINE;
      declinedOn: string | null;
    }
  | {
      action: typeof StatusUpdateTypes.DONT_GO_BACK;
      declinedOn: string | null;
      status: TOfferStatus | null;
      transfer: TTransferData | null;
    };

export function reduceOfferStatusUpdate(
  currentData: TUseStatusUpdate,
  newData: TUseStatusUpdateActions
) {
  switch (newData.action) {
    case StatusUpdateTypes.CANCEL:
      return {
        declinedOn: null,
        status: OfferStatus.OFFER_ACTIVE as TOfferStatus,
        transfer: null,
      };
    case StatusUpdateTypes.OPEN_CONFIRM_TRANSFER_FORM:
      return {
        declinedOn: null,
        status: OfferStatus.AWAITING_CONFIRM_TRANSFER_FROM_USER as TOfferStatus,
        transfer: newData.transfer,
      };
    case StatusUpdateTypes.INITIATE_TRANSFER:
      return {
        declinedOn: null,
        status:
          OfferStatus.AWAITING_TRANSFER_INITIATED_FROM_API as TOfferStatus,
        transfer: newData.transfer,
      };
    case StatusUpdateTypes.THROW_TRANSFER_ERROR:
      return {
        declinedOn: null,
        status: OfferStatus.TRANSFER_FAILED as TOfferStatus,
        transfer: newData.transfer,
      };
    case StatusUpdateTypes.OPEN_CONFIRM_DECLINE_FORM:
      return {
        declinedOn: newData.declinedOn,
        status: OfferStatus.AWAITING_CONFIRM_DECLINE_FROM_USER as TOfferStatus,
        transfer: null,
      };
    case StatusUpdateTypes.INITIATE_DECLINE:
      return {
        declinedOn: newData.declinedOn,
        status:
          OfferStatus.AWAITING_DECLINED_INITIATED_FROM_API as TOfferStatus,
        transfer: null,
      };
    case StatusUpdateTypes.DONT_GO_BACK:
      if (!currentData.status || !newData.status) {
        return currentData;
      }

      if (
        OrderedOfferStatus[newData.status] >
        OrderedOfferStatus[currentData.status]
      ) {
        return {
          declinedOn: newData.declinedOn,
          status: newData.status,
          transfer: newData.transfer,
        };
      } else {
        return currentData;
      }
    default:
      return currentData;
  }
}

export const useOfferStatusUpdate: (
  initialData: TUseStatusUpdate
) => [TUseStatusUpdate, React.Dispatch<TUseStatusUpdateActions>] = (
  initialData
) => {
  const { issn, submissionUuid } = useUrl();
  const navigate = useNavigate();

  const [update, dispatchUpdate] = useReducer(reduceOfferStatusUpdate, {
    declinedOn: initialData.declinedOn,
    status: initialData.status,
    transfer: initialData.transfer,
  });

  useEffect(() => {
    if (!update.status) {
      return;
    }

    if (isOfferStatusActive(update.status) && issn) {
      navigate(`/transfers/${submissionUuid}/${issn}`, { replace: true });
    } else if (isOfferStatusActive(update.status) && !issn) {
      navigate(`/transfers/${submissionUuid}`, { replace: true });
    } else if (
      isOfferStatusAwaitingConfirmTransferFromUser(update.status) ||
      isOfferStatusAwaitingConfirmDeclineFromUser(update.status)
    ) {
      navigate(`/transfers/${submissionUuid}/status`);
    } else {
      navigate(`/transfers/${submissionUuid}/status`, { replace: true });
    }
  }, [update.status]);

  return [update, dispatchUpdate];
};
