import React, { forwardRef } from "react";

import { Box } from "../generic";
import { statusMessageTitleProps, AssistanceMessage } from "./StatusView.utils";
import MoreTransferOptions from "../MoreTransferOptions";
import { TSubmissionData } from "../lib";
import ViewContainer from "../ViewContainer";

const OfferExpiredMessage = forwardRef(
  (
    {
      submissionData,
    }: {
      submissionData: TSubmissionData;
    },
    ref: React.Ref<HTMLHeadingElement>
  ) => {
    const { title: manuscriptTitle } = submissionData;

    return (
      <ViewContainer
        viewName="status"
        htmlTitle={`Suggestions expired for ${manuscriptTitle}`}
        topTitle="Status update for"
        submissionData={submissionData}
      >
        <Box color="info" iconName="info-solid-circle" variant="background">
          <h1 {...statusMessageTitleProps} ref={ref}>
            All transfer suggestions expired
          </h1>
          <AssistanceMessage />
        </Box>
        <MoreTransferOptions />
      </ViewContainer>
    );
  }
);

export default OfferExpiredMessage;
