import { css } from "@emotion/react";

export const _utilBarContainerCSS = css`
  display: flex;
  flex-wrap: wrap;
  gap: var(--row-gap) var(--column-gap);
  align-items: center;
  & > * {
    margin: 0;
  }
  &.utilbar-vertical {
    flex-direction: column;
    & > * {
      width: 100%;
    }
    * {
      text-align: center;
    }
  }
`;
