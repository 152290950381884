import { css } from "@emotion/react";
import { _minMediaCSS } from "../lib";

export const _goaTitleCSS = css`
  font-weight: bold;
  font-size: var(--font-size-l);
`;

export const _goaDescCSS = css`
  margin: 0;
`;

export const _declineAllContainerCSS = css`
  --color-interaction: rgb(var(--es-color-link-blue-rgb));
  --color-on-interaction: white;
`;

export const _declineAllTextCSS = css`
  --margin: 0;
  ${_minMediaCSS("s")} {
    flex: 1;
  }
`;

export const _declineAllHeadingCSS = css`
  font-size: var(--font-size-m);
`;
