import { css, keyframes } from "@emotion/react";

export const _loadingBarContainerCSS = () => css`
  width: 100%;
  max-width: 240px;
  height: var(--spacing-m);
  background-color: rgb(var(--es-color-grey1-rgb));
  border-radius: 8px;
  position: relative;
  overflow: hidden;
`;

const move = keyframes`
  0% {
    left: 0;
  }
  50% {
    left: 80%;
  }
  100% {
    left: 0;
  }
`;

export const _loadingBarCSS = css`
  width: 20%;
  top: 0;
  bottom: 0;
  background-color: var(--color-els-blue);
  border-radius: 10px;
  position: absolute;
  animation: ${move} 2s infinite;
`;
