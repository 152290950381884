import React from "react";

import { TMergeElementProps } from "../../lib";

import { _badgeCSS } from "./Badge.css";

export const Badge = ({
  children,
  forwardCSS,
  testId,
  ...rest
}: TMergeElementProps<
  "span",
  {
    children: React.ReactNode | React.ReactNode[]; // Required
    testId: string; // Required
  }
>) => (
  <span css={[_badgeCSS, forwardCSS]} data-testid={testId} {...rest}>
    {children}
  </span>
);
