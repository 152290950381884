import { TOfferStatus, formatStatus } from "../lib";
import { TViewName } from "./ViewContainer.types";

export const translateViewNameToAAPageName = ({
  viewName,
  status,
}: {
  viewName: TViewName;
  status: TOfferStatus;
}) => {
  if (
    // Stays the same
    viewName === "suggestions" ||
    viewName.startsWith("evaluate:") // Evaluate status is embedded in the view name for the experiment
  ) {
    return viewName;
  } else if (
    // Changes
    viewName === "detail"
  ) {
    return "journal";
  } else if (
    // Concatenates
    viewName === "status"
  ) {
    return `status:${formatStatus(status)}`; // What we'll do for the evaluate view if it becomes permanent
  } else {
    return "error";
  }
};
