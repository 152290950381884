import React from "react";

import {
  _footerCSS,
  _footerContainerCSS,
  _footerContentCSS,
  _footerLinksCSS,
} from "./ApplicationFooter.css";
import { ExternalLink, Logo } from "../generic";

const ApplicationFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer
      css={[_footerContainerCSS, _footerCSS]}
      id="footer" // Not sure what this is for
      role="contentinfo"
    >
      <div>
        <ExternalLink
          aria-label="Elsevier homepage (external site, opens in new window)"
          analyticsRegion="ah-footer"
          analyticsName="ah-relx"
          href="https://www.elsevier.com/"
          showIcon={false}
          styleType="nav"
          testId="elsevier-footer-link"
        >
          <Logo name="elsevier" />
        </ExternalLink>
      </div>
      <div css={_footerContentCSS}>
        <p>
          All content on this site: Copyright ©{" "}
          <time dateTime={currentYear.toString()}>{currentYear}</time> Elsevier
          B.V., its licensors, and contributors. All rights are reserved,
          including those for text and data mining, AI training, and similar
          technologies. For all open access content, the Creative Commons
          licensing terms apply.
        </p>
        <p>
          Cookies are used by this site.{" "}
          <button
            id="ot-sdk-btn"
            className="ot-sdk-show-settings"
            data-testid="cookie-settings-button"
          >
            Cookie settings
          </button>
        </p>
        <p css={_footerLinksCSS}>
          <ExternalLink
            analyticsRegion="ah-footer"
            analyticsName="ah-terms-and-conditions"
            href="https://www.elsevier.com/legal/elsevier-website-terms-and-conditions"
            showIcon={false}
            styleType="text"
            testId="terms-and-conditions-link"
          >
            Terms and conditions
          </ExternalLink>
          <ExternalLink
            href="https://www.elsevier.com/legal/privacy-policy"
            analyticsRegion="ah-footer"
            analyticsName="ah-privacy-policy"
            showIcon={false}
            styleType="text"
            testId="privacy-policy-link"
          >
            Privacy policy
          </ExternalLink>
        </p>
      </div>
      <div>
        <ExternalLink
          href="https://www.relx.com/"
          analyticsRegion="ah-footer"
          analyticsName="ah-relx"
          aria-label="RELX ™ homepage (external site, opens in new window)"
          showIcon={false}
          styleType="nav"
          testId="relx-footer-link"
        >
          <Logo name="relx" />
        </ExternalLink>
      </div>
    </footer>
  );
};

export default ApplicationFooter;
