import { RefObject, useEffect, useState } from "react";

const useHandleClose = ({
  tooltipRef,
  isOpen,
  setIsOpen,
  isHovered,
  setIsHovered,
}: {
  tooltipRef: RefObject<HTMLDivElement>;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  isHovered: boolean;
  setIsHovered: (value: boolean) => void;
}) => {
  useEffect(() => {
    if (!tooltipRef.current || !isOpen) {
      return;
    }

    const handleFocusOut = (e: FocusEvent) => {
      // don't close the tooltip
      //  if entire document lost focus (switch to another tab)
      //  or if the element recieveing focus is also within the tooltip
      //  or if the element is hovered
      if (
        !document.hasFocus() ||
        tooltipRef.current?.contains(e.relatedTarget as Node | null) ||
        isHovered
      ) {
        return;
      }
      setIsOpen(false);
    };
    window.addEventListener("focusout", handleFocusOut);

    const handleHoverLeave = () => {
      setIsHovered(false);
      //If the tooltip is focused, keep it open
      if (!tooltipRef.current?.contains(document.activeElement)) {
        setIsOpen(false);
      }
    };
    tooltipRef.current.addEventListener("mouseleave", handleHoverLeave);

    return () => {
      window.removeEventListener("focusout", handleFocusOut);
      tooltipRef.current?.removeEventListener("mouseleave", handleHoverLeave);
    };
  }, [tooltipRef, isOpen, setIsOpen, isHovered, setIsHovered]);
};

const useHandleOpen = ({
  tooltipRef,
  isOpen,
  setIsOpen,
  setIsHovered,
}: {
  tooltipRef: RefObject<HTMLDivElement>;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  setIsHovered: (value: boolean) => void;
}) => {
  useEffect(() => {
    if (!tooltipRef.current || isOpen) {
      return;
    }

    const handleFocus = () => {
      // as we listen on the window, don't open the popup
      //  if the tooltip container doesn't contain the focused element
      if (!tooltipRef.current?.contains(document.activeElement)) {
        return;
      }
      setIsOpen(true);
    };
    window.addEventListener("focusin", handleFocus);

    const handleHover = () => {
      setIsHovered(true);
      setIsOpen(true);
    };
    tooltipRef.current.addEventListener("mouseover", handleHover);

    return () => {
      window.removeEventListener("focusin", handleFocus);
      tooltipRef.current?.removeEventListener("mouseover", handleHover);
    };
  }, [tooltipRef, isOpen, setIsOpen, setIsHovered]);
};

const useIsOpen = ({
  tooltipRef,
}: {
  tooltipRef: RefObject<HTMLDivElement>;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useHandleOpen({ tooltipRef, isOpen, setIsOpen, setIsHovered });
  useHandleClose({ tooltipRef, isOpen, setIsOpen, isHovered, setIsHovered });

  return { isOpen };
};

export default useIsOpen;
