import React from "react";

import {
  AppErrorCode,
  useFocusRef,
  TAppError,
  TSubmissionData,
  appTitle,
} from "../lib";
import { Box, PendoErrorExplainer } from "../generic";
import ViewContainer from "../ViewContainer";
import { errorMessages } from "./ErrorMessages";
import SupportCenterLink from "../SupportCenterLink";

const ErrorView = (props: { submissionData?: TSubmissionData } & TAppError) => {
  const focusRef = useFocusRef<HTMLHeadingElement>({
    documentCookie: document.cookie,
    dependencies: [], // Run once
    shouldFocus: true,
  });

  const { title, content } = errorMessages(props.errorType);

  return (
    <ViewContainer
      viewName="error"
      htmlTitle={`${title}: ${appTitle}`}
      {...props}
    >
      <Box
        color="info"
        variant="border"
        iconName="alert-solid-circle"
        testId="error-message"
      >
        <h1 ref={focusRef} tabIndex={-1}>
          {title}
        </h1>
        <p>{content}</p>
        <p>
          If you continue to receive this message please contact our{" "}
          <SupportCenterLink
            analyticsRegion="ah-error"
            analyticsName="ah-support-center"
            styleType="text-underlined"
            testId="support-center-error-link"
          />
        </p>
        <PendoErrorExplainer errorCode={AppErrorCode[props.errorType]} />
        <p data-testid="error-message-code">
          Message code: {AppErrorCode[props.errorType]}
        </p>
      </Box>
    </ViewContainer>
  );
};

export default ErrorView;
