import React, { forwardRef } from "react";

import {
  TViewContextProps,
  TSubmissionData,
  TTransferData,
  StatusUpdateTypes,
  trackMenuButtonClick,
} from "../lib";
import {
  statusMessageTitleProps,
  statusMessageContentProps,
} from "./StatusView.utils";
import { Box, Button } from "../generic";
import ViewContainer from "../ViewContainer";
import * as css from "./StatusView.css";

const LegacyAwaitingAuthenticationForm = forwardRef(
  (
    {
      dispatchOfferStatusUpdate,
      submissionData,
    }: {
      dispatchOfferStatusUpdate: TViewContextProps["dispatchOfferStatusUpdate"];
      submissionData: TSubmissionData & {
        transfer: TTransferData;
      };
    },
    ref: React.Ref<HTMLHeadingElement>
  ) => {
    const {
      title: manuscriptTitle,
      transfer: { targetJournal },
    } = submissionData;

    const onReturnToSuggestions = () => {
      dispatchOfferStatusUpdate({
        action: StatusUpdateTypes.CANCEL,
      });
      trackMenuButtonClick("cancelAwaitingAuthentication");
    };

    return (
      <ViewContainer
        viewName="status"
        htmlTitle={`Transfer failed for ${manuscriptTitle}`}
        topTitle="Status update for"
        submissionData={submissionData}
      >
        <Box color="alert" iconName="failed-solid-circle" variant="background">
          <h1 {...statusMessageTitleProps} ref={ref}>
            Transfer failed
          </h1>
          <p {...statusMessageContentProps}>
            We&apos;re sorry, transfer of your manuscript to{" "}
            <em>{targetJournal.title}</em> failed. This is a problem with our
            system. Please return to the list of suggested journals and try
            again.
          </p>
          <Button
            forwardCSS={css._transferSummaryButtonsCSS}
            icon={{ name: "navigate-left", position: "before" }}
            onClick={onReturnToSuggestions}
            testId="return-to-suggestions-button"
          >
            Return to suggested journals
          </Button>
        </Box>
      </ViewContainer>
    );
  }
);

export default LegacyAwaitingAuthenticationForm;
