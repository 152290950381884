import React from "react";

import { reactKey, TMergeElementProps } from "../../lib";
import { listOfTermsListCSS, listOfTermsItemCSS } from "./ListOfTerms.css";

export const ListOfTerms = ({
  forwardCSS,
  terms,
  testId,
  ...rest
}: TMergeElementProps<
  "ul",
  {
    terms: string[];
  }
>) =>
  terms && terms.length > 0 ? (
    <ul css={[listOfTermsListCSS, forwardCSS]} data-testid={testId} {...rest}>
      {terms.map((term, index) => (
        <li key={reactKey({ term, index })} css={listOfTermsItemCSS}>
          {term}
        </li>
      ))}
    </ul>
  ) : null;
