import React, { ReactElement, JSXElementConstructor } from "react";
import { MemoryRouter, Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { render, RenderOptions } from "@testing-library/react";

import { AppErrorType } from "./types";
import DetailViewRouter from "../DetailView";
import ErrorBoundary from "../ErrorBoundary";
import ErrorView from "../ErrorView";
import StartView from "../StartView";
import StatusViewRouter from "../StatusView";
import SuggestionsViewRouter from "../SuggestionsView";
import ValidateAcceptLink from "../ValidateAcceptLink";
import EvaluateViewRouter from "../EvaluateView/EvaluateViewRouter";

export const ComponentInContext = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  // Adjust query client options to keep some tests from failing
  const testingQueryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 0,
      },
    },
  });

  return (
    <ErrorBoundary>
      <QueryClientProvider client={testingQueryClient}>
        {children}
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export const renderInContext = (
  ui: ReactElement<unknown, string | JSXElementConstructor<unknown>>,
  options?: RenderOptions
) => render(ui, { wrapper: ComponentInContext, ...options });

export const AppWithRouter = ({ route }: { route: string }) => (
  <MemoryRouter initialEntries={[route]}>
    <Routes>
      <Route path="/transfers/*">
        <Route index={true} element={<ValidateAcceptLink />} />
        <Route path=":submissionUuid" element={<StartView />}>
          <Route index={true} element={<SuggestionsViewRouter />} />
          <Route path="status" element={<StatusViewRouter />} />
          <Route path="evaluate" element={<EvaluateViewRouter />} />
          <Route path=":issn" element={<DetailViewRouter />} />
          <Route
            path="*"
            element={<ErrorView errorType={AppErrorType.INVALID_URL} />}
          />
        </Route>
      </Route>
      <Route
        path="/resubmissions/*"
        element={
          <Navigate
            to={route.replace("/resubmissions", "/transfers")}
            replace={true}
          />
        }
      />
      <Route
        path="*"
        element={<ErrorView errorType={AppErrorType.INVALID_URL} />}
      />
    </Routes>
  </MemoryRouter>
);
