import React from "react";

import { _loadingBarCSS, _loadingBarContainerCSS } from "./LoadingBar.css";

const LoadingBar = () => (
  <div css={_loadingBarContainerCSS}>
    <div css={_loadingBarCSS} />
  </div>
);

export default LoadingBar;
