import React from "react";

import SupportCenterLink from "../SupportCenterLink";

export const statusMessageTitleProps = {
  "data-testid": "status-message-title",
  tabIndex: -1,
};

export const statusMessageContentProps = {
  "data-testid": "status-message-content",
};

export const AssistanceMessage = () => (
  <p>
    If you have questions or need assistance please contact us via our{" "}
    <SupportCenterLink
      analyticsRegion="ah-status"
      analyticsName="ah-support-center"
      styleType="text-underlined"
      testId="support-center-status-link"
    />
  </p>
);
