import React from "react";

import { buttonLinkCSS } from "./BL.css";
import { ButtonLinkInside } from "./BLInside";
import { TLink } from "./BL.types";

export const HashLink = React.forwardRef(
  (
    {
      analyticsRegion,
      analyticsName,
      children,
      className: propsClassName,
      forwardCSS,
      href,
      icon,
      styleType = "text",
      testId,
      ...rest
    }: TLink,
    ref: React.Ref<HTMLAnchorElement>
  ) => {
    const classNames: string[] = [];

    classNames.push(`button button-${styleType}`);

    if (propsClassName) {
      classNames.push(propsClassName);
    }

    return (
      <a
        {...rest}
        className={classNames.join(" ")}
        css={[buttonLinkCSS({ styleType }), forwardCSS]}
        data-aa-region={analyticsRegion}
        data-aa-name={analyticsName}
        data-testid={testId}
        href={href}
        ref={ref}
      >
        <ButtonLinkInside icon={icon}>{children}</ButtonLinkInside>
      </a>
    );
  }
);
