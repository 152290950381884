/*
  The evils of enums in Typescript: https://dev.to/ivanzm123/dont-use-enums-in-typescript-they-are-very-dangerous-57bh
  Best solution: https://www.typescriptlang.org/docs/handbook/enums.html#objects-vs-enums
*/

export const OpenAccessType = {
  FULL: "FULL",
  HYBRID: "HYBRID",
  NONE: "NONE",
  SUBSIDISED: "SUBSIDISED",
} as const;
export type TOpenAccessType =
  (typeof OpenAccessType)[keyof typeof OpenAccessType];

export type TOpenAccessTypeFull = typeof OpenAccessType.FULL;
export type TOpenAccessTypeHybrid = typeof OpenAccessType.HYBRID;
export type TOpenAccessTypeNone = typeof OpenAccessType.NONE;
export type TOpenAccessTypeSubsidised = typeof OpenAccessType.SUBSIDISED;

export function isOpenAccessTypeFull(
  t: TOpenAccessType
): t is TOpenAccessTypeFull {
  return t === OpenAccessType.FULL;
}

export function isOpenAccessTypeHybrid(
  t: TOpenAccessType
): t is TOpenAccessTypeHybrid {
  return t === OpenAccessType.HYBRID;
}

export function isOpenAccessTypeNone(
  t: TOpenAccessType
): t is TOpenAccessTypeNone {
  return t === OpenAccessType.NONE;
}

export function isOpenAccessTypeSubsidised(
  t: TOpenAccessType
): t is TOpenAccessTypeSubsidised {
  return t === OpenAccessType.SUBSIDISED;
}
