import React from "react";
import { TMergeElementProps } from "../../lib";
import { _hrBase } from "./HorizonalRule.css";

type TBoxProps = TMergeElementProps<
  "hr",
  {
    spacing?: "cozy" | "roomy" | "none";
    variant?: "single" | "loop";
  }
>;

export const HorizonalRule = ({
  forwardCSS,
  spacing = "roomy",
  variant = "single",
  ...rest
}: TBoxProps) => {
  return (
    <hr
      className={`hr-${variant} hr-${spacing}`}
      css={[_hrBase, forwardCSS]}
      {...rest}
    />
  );
};
