import { useQueryClient, useMutation } from "@tanstack/react-query";
import { apiClient } from "../apiClient";

export const useDeclineAll = ({
  submissionUuid,
  onMutate,
}: {
  submissionUuid: string;
  onMutate: () => void;
}) => {
  const queryClient = useQueryClient();

  const result = useMutation({
    mutationFn: () => {
      return apiClient({
        method: "post",
        url: `/submissions/decline/${submissionUuid}`,
      });
    },
    retry: 3,
    retryDelay: (attempt) => attempt * 60 * 1000,
    onMutate: async () => {
      onMutate();
      queryClient.cancelQueries({
        queryKey: ["submissionData", submissionUuid],
      });
    },
    onSettled: () => {
      // Mark submission data query as stale but don't refetch per se
      // Is react-query still refetching too soon? Check the query client settings in the App component.
      queryClient.invalidateQueries({
        queryKey: ["submissionData", submissionUuid],
      });
    },
  });

  return result;
};
