import React, { forwardRef } from "react";
import { Box } from "../generic";
import JournalCard from "../JournalCard";
import { TSubmissionData, TTransferData } from "../lib";
import {
  statusMessageTitleProps,
  statusMessageContentProps,
} from "./StatusView.utils";
import ViewContainer from "../ViewContainer";
import LanguageBannerRouter from "../LanguageBanner/LanguageBannerRouter";

const TransferInitiatedMessage = forwardRef(
  (
    {
      submissionData,
    }: {
      submissionData: TSubmissionData & {
        transfer: TTransferData;
      };
    },
    ref: React.Ref<HTMLHeadingElement>
  ) => {
    const {
      languageExperiment,
      pubDNumber,
      submissionUuid,
      status: offerStatus,
      title: manuscriptTitle,
      transfer: { initiatedDate, targetJournal },
    } = submissionData;

    return (
      <ViewContainer
        viewName="status"
        htmlTitle={`Transfer confirmed for ${manuscriptTitle}`}
        topTitle="Status update for"
        submissionData={submissionData}
      >
        <Box color="info" iconName="info-solid-circle" variant="background">
          <h1 {...statusMessageTitleProps} ref={ref}>
            Transfer confirmed
          </h1>
          <p {...statusMessageContentProps}>
            Thank you! Transfer of your manuscript to{" "}
            <em>{targetJournal.title}</em> was confirmed on{" "}
            <strong>{initiatedDate}</strong> and is currently in progress. You
            will receive an email from them with information about completing
            your submission. You will also be able to make revisions.
          </p>
        </Box>
        <JournalCard analyticsRegion="ah-status" journal={targetJournal} />
        <LanguageBannerRouter
          languageExperiment={languageExperiment}
          offerStatus={offerStatus}
          pubDNumber={pubDNumber}
          position="after-main-heading"
          submissionUuid={submissionUuid}
        />
      </ViewContainer>
    );
  }
);

export default TransferInitiatedMessage;
