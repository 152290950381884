import { OrderedEvaluationStatus, TEvaluationStatus } from "../lib";

export function reduceEvaluationStatusUpdate(
  currentData: TEvaluationStatus,
  newData: TEvaluationStatus
) {
  if (OrderedEvaluationStatus[newData] > OrderedEvaluationStatus[currentData]) {
    return newData;
  } else {
    return currentData;
  }
}
