import React from "react";

import { TAnalyticsRegion } from "../lib";
import { ExternalLink } from "../generic";
import { TButtonLinkStyleType } from "../generic/ButtonsAndLinks/BL.types";

const SupportCenterLink = ({
  analyticsRegion,
  analyticsName,
  styleType,
  testId,
}: {
  analyticsRegion: TAnalyticsRegion;
  analyticsName: string; // Not standarised unfortunately
  styleType: TButtonLinkStyleType;
  testId: string;
}) => {
  const supportCenterUrl =
    "https://service.elsevier.com/app/contact/supporthub/publishing/role/author/c/10528/subcat/10609";

  return (
    <ExternalLink
      analyticsRegion={analyticsRegion}
      analyticsName={analyticsName}
      href={supportCenterUrl}
      styleType={styleType}
      testId={testId}
    >
      Support Center
    </ExternalLink>
  );
};

export default SupportCenterLink;
