import React from "react";

import {
  _srOnlyCSS,
  emdash,
  reactKey,
  TInsightData,
  TMergeElementProps,
} from "../lib";

import * as css from "./JournalMetrics.css";

type TJournalMetrics = TMergeElementProps<
  "ul",
  {
    analyticsRegion: string;
    citeScore?: TInsightData;
    impactFactor?: TInsightData;
    acceptanceRate?: TInsightData;
    timeToFirstDecision?: TInsightData;
    timeToAcceptance?: TInsightData;
    ranking: number;
  }
>;

const JournalStatsMetrics = ({
  citeScore,
  forwardCSS,
  impactFactor,
  acceptanceRate,
  timeToFirstDecision,
  timeToAcceptance,
}: TJournalMetrics) => {
  const enrichedInsights = [
    {
      ...impactFactor,
      id: "impact-factor",
      title: "Impact Factor",
      units: "",
      unitsSingular: "",
    },
    {
      ...citeScore,
      id: "citescore",
      title: "CiteScore",
      units: "",
      unitsSingular: "",
    },
    {
      ...timeToFirstDecision,
      id: "time-to-first-decision",
      title: "Time to 1st Decision",
      units: " days",
      unitsSingular: " day",
    },
    {
      ...timeToAcceptance,
      id: "time-to-final-decision",
      title: "Time to acceptance",
      units: " days",
      unitsSingular: " day",
    },
    {
      ...acceptanceRate,
      id: "acceptance-rate",
      title: "Acceptance Rate",
      units: "%",
      unitsSingular: "",
    },
  ];

  return (
    <ul
      css={[forwardCSS, css._metricsStatsInfoCSS]}
      data-testid="journal-metrics"
    >
      {enrichedInsights.map((insight) => {
        const { id, title, units, unitsSingular, value } = insight;

        // !value will return true if value is 0, which we don't want
        const hasValue = !(value === null || value === undefined);

        return (
          <li key={reactKey({ insight })}>
            <span css={css._metricsTitleCSS} data-testid={id}>
              {title}
            </span>
            {hasValue ? (
              <span css={css._metricsValueCSS}>
                {value} {unitsSingular && value === 1 ? unitsSingular : units}
              </span>
            ) : (
              <>
                <span aria-hidden="true">{emdash}</span>
                <span css={_srOnlyCSS}>No data shown</span>
              </>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default JournalStatsMetrics;
